<template>
  <div class="index">
    <van-nav-bar title="发布病例" fixed left-arrow @click-left="$router.back()" style="z-index: 9999999" />
    <div class="index-head">
      <img src="@/assets/image/questionnaire/titlebgc.png" />
      <div class="index-head-name">
        <div>言 ' 荜 ' 有据，未来无 ' 幽 ' </div>
        <div>中西医结合根除幽门螺杆菌临床观察</div>
      </div>
    </div>
    <div class="biaodan">
      <el-form :model="form" :rules="rules" ref="form" class="form">
        <div class="inform">
          <!-- 患者基本系  -->
          <div ref="inref">
            <div class="index-title">
              <div class="index-title-img">
                <img src="@/assets/image/questionnaire/组 747@2x.png" />
              </div>
              <div class="index-title-name">患者基本信息</div>
              <div class="index-title-xia"></div>
            </div>
            <!-- 身高体重年龄 -->
            <div class="inform-box">
              <el-form-item prop="name" ref="name">
                <div class="inform-box-item-flex">
                  <div class="inform-box-item-flex-name">患者姓名：</div>
                  <el-input v-model="form.name" placeholder="请输入姓名" />
                </div>
              </el-form-item>

              <el-form-item prop="age" ref="age">
                <div class="inform-box-item-flex">
                  <div class="inform-box-item-flex-name">年龄：</div>
                  <el-input v-model="form.age" placeholder="请输入年龄" />
                  <div class="inform-box-item-flex-input-rig">岁</div>
                </div>
              </el-form-item>

              <el-form-item prop="sex" ref="sex" style="margin-bottom: 0;">
                <div class="inform-box-item-flex">
                  <div class="inform-box-item-flex-name">性别：</div>
                  <el-radio-group v-model="form.sex">
                    <el-radio :label="1" style="margin: 0 54px 0 0">男</el-radio>
                    <el-radio :label="2" style="margin-top: 0">女</el-radio>
                  </el-radio-group>
                </div>
              </el-form-item>
            </div>
            <!-- Hp 检查 -->
            <div class="inform-box" style="margin-top: 10px">
              <div class="inform-box-title">Hp 检查：</div>
              <!-- 检查日期 -->
              <el-form-item prop="hp_check_date" ref="hp_check_date">
                <div class="inform-box-buliang"
                  style="
                                                                                                                                                                                                                                                                                                                                                                                                                                  display: flex; 
                                                                                                                                                                                                                                                                                                                                                                                                                                  align-items: center;
                                                                                                                                                                                                                                                                                                                                                                                                                                  justify-content: space-between; 
                                                                                                                                                                                                                                                                                                                                                                                                                                  background-color: #fff;
                                                                                                                                                                                                                                                                                                                                                                                                                                  margin-top: 0;
                                                                                                                                                                                                                                                                                                                                                                                                                                  padding-bottom: 5px
                                                                                                                                                                                                                                                                                                                                                                                                                                  ;
                                                                                                                                                                                                                                                                                                                                                                                                                                ">
                  <div style="width:80px; " class="form-xing-xin">
                    检查日期：
                  </div>
                  <el-date-picker style="background: #fff;" type="date" placeholder="选择日期"
                    v-model="form.hp_check_date"></el-date-picker>
                </div>
              </el-form-item>
              <div class="inform-box-input">
                <el-form-item prop="hp_check_method" ref="hp_check_method" label="检查方法：" class="xinde-radio">
                  <el-radio-group v-model="form.hp_check_method">
                    <el-radio :label="1">13C‐UBT</el-radio>
                    <el-radio :label="2" style="margin-left: 38px;">14C‐UBT</el-radio>
                    <el-radio :label="3">快速尿素酶试验</el-radio>
                    <el-radio :label="4">胃黏膜组织RUT</el-radio>
                    <el-radio :label="5">其他</el-radio>
                  </el-radio-group>
                  <el-form-item prop="hp_check_method_input" ref="hp_check_method_input" v-if="form.hp_check_method == 5">
                    <el-input class="form-input-xia" v-model="form.hp_check_method_input" placeholder="请输入内容"
                      style="left: 20%"></el-input>
                  </el-form-item>
                </el-form-item>
              </div>
              <el-form-item prop="hp_check_result" ref="hp_check_result" label="检查结果：" style="margin: 35px 0 0 0"
                class="xinde-radio">
                <el-radio-group v-model="form.hp_check_result">
                  <el-radio :label="1">Hp 阳性</el-radio>
                  <el-radio :label="2">Hp 阴性</el-radio>
                </el-radio-group>
              </el-form-item>
            </div>
            <!-- 疾病诊断 -->
            <div class="inform-box shurukuang">
              <el-form-item prop="disease_diagnosis" ref="disease_diagnosis" label="疾病诊断：" class="xinde-radio"
                style="margin-bottom: 0;">
                <el-radio-group v-model="form.disease_diagnosis">
                  <el-radio :label="1">慢性浅表性胃炎</el-radio>
                  <el-radio :label="2" style="margin-left:0px;">慢性萎缩性胃炎</el-radio>
                  <el-radio :label="3">功能性消化不良</el-radio>
                  <el-radio :label="4">消化性溃疡</el-radio>
                  <el-radio :label="5">胃食管反流</el-radio>
                  <div><el-radio :label="6">其他</el-radio></div>
                </el-radio-group>
                <el-form-item prop="disease_diagnosis_input" ref="disease_diagnosis_input"
                  v-if="form.disease_diagnosis == 6">
                  <el-input class="form-input-xia" v-model="form.disease_diagnosis_input" placeholder="请输入内容"
                    style="left: 20%"></el-input>
                </el-form-item>
              </el-form-item>
            </div>
            <!-- 胃镜诊断： -->
            <div class="inform-box inform-box-input" style="margin-top: 10px">
              <el-form-item label="胃镜诊断：" style="margin: 8px 0 0 0" prop="gastroscopy_diagnosis"
                ref="gastroscopy_diagnosis">
                <el-radio-group v-model="form.gastroscopy_diagnosis">
                  <el-radio :label="1">开展内镜</el-radio>
                  <el-radio :label="2">未开展内镜检查</el-radio>
                </el-radio-group>
              </el-form-item>
              <div class="inform-box-fen" v-if="form.gastroscopy_diagnosis == 1">
                <el-form-item prop="gastroscopy_diagnosis_input" ref="gastroscopy_diagnosis_input"
                  style="width: 100%; margin-bottom: 0">
                  <el-input v-model="form.gastroscopy_diagnosis_input" placeholder="请输入诊断描述" type="textarea" />
                </el-form-item>
              </div>
            </div>
            <!-- 病理诊断： -->
            <div class="inform-box inform-box-input" style="margin-top: 10px">
              <el-form-item label="病理诊断：" style="margin: 8px 0 0 0" prop="case_diagnosis" ref="case_diagnosis">
                <el-radio-group v-model="form.case_diagnosis">
                  <el-radio :label="1">开展内镜</el-radio>
                  <el-radio :label="2">未开展内镜检查</el-radio>
                </el-radio-group>
              </el-form-item>
              <div class="inform-box-fen" v-if="form.case_diagnosis == 1">
                <el-form-item prop="case_diagnosis_input" ref="case_diagnosis_input"
                  style="width: 100%; margin-bottom: 0">
                  <el-input v-model="form.case_diagnosis_input" placeholder="请输入诊断描述" type="textarea" />
                </el-form-item>
              </div>
            </div>
            <!-- 既往Hp感染治疗史： -->
            <div class="inform-box" style="margin-top: 10px">
              <el-form-item label="既往Hp感染治疗史：" prop="hp_treatment_history" ref="hp_treatment_history"
                style="margin: 8px 0 0 0">
                <el-radio-group v-model="form.hp_treatment_history">
                  <el-radio :label="1">是</el-radio>
                  <el-radio :label="2">否</el-radio>
                </el-radio-group>
              </el-form-item>
              <div v-if="form.hp_treatment_history == 1" class="inform-box-jiancha" style="padding-top: 0">
                <el-form-item prop="hp_treatment_history_input" ref="hp_treatment_history_input" style="margin-bottom: 0">
                  <div style="color: #606266">用药方案及疗程：</div>
                  <el-input v-model="form.hp_treatment_history_input" placeholder="请输入内容" type="textarea" />
                </el-form-item>
              </div>
            </div>
          </div>
          <!-- 治疗前症状评分 -->
          <div style="margin-top: 20px" ref="zhongyiref">
            <div class="index-title">
              <div class="index-title-img">
                <img src="@/assets/image/questionnaire/zhonyi.png" />
              </div>
              <div class="index-title-name">治疗前症状评分</div>
              <div class="index-title-xia" style="width: 107px"></div>
            </div>
            <!-- 前 -->
            <div class="inform-box" style="margin-bottom: 10px">
              <div class="zhongyi-head">主要症状严重程度评分表</div>
              <div class="zhongyi-text">
                <div class="zhongyi-text-zhu">注：</div>
                <div class="zhongyi-text-name">
                  <div>1：无症状</div>
                  <div>2：轻度</div>
                  <div>3：中度</div>
                  <div>4：严重</div>
                  <div>5：极严重</div>
                </div>
              </div>
              <!-- 滑块 -->
              <div class="zhongyi-box">
                <div class="zhongyi-box-head">上腹痛</div>
                <div class="zhongyi-box-slider">
                  <el-form-item prop="pre_degree_ep_pain" ref="pre_degree_ep_pain" style="margin-bottom: 0;">
                    <el-slider style="width: 225px" v-model="form.pre_degree_ep_pain" show-stops :min="0" :max="5"
                      :marks="{ 0: '', 1: '', 2: '', 3: '', 4: '', 5: '' }" :show-tooltip="false">
                    </el-slider>
                  </el-form-item>
                  <div :style="style1" :class="
                    form.pre_degree_ep_pain == 1
                      ? 'form-zhongyi-box-slider-cen-name1'
                      : 'form-zhongyi-box-slider-cen-name'
                  ">
                    {{ form.pre_degree_ep_pain }}
                  </div>
                  <div :style="style1" class="form-zhongyi-box-slider-cen-zheng">
                    <span v-if="form.pre_degree_ep_pain == 1">
                      无症状
                    </span>
                    <span v-if="form.pre_degree_ep_pain == 2">
                      轻度
                    </span>
                    <span v-if="form.pre_degree_ep_pain == 3">
                      中度
                    </span>
                    <span v-if="form.pre_degree_ep_pain == 4">
                      严重
                    </span>
                    <span class="zhong-zheng5" v-if="form.pre_degree_ep_pain == 5">
                      极严重
                    </span>
                  </div>
                </div>
              </div>
              <!-- 滑块 -->
              <!-- 滑块 -->
              <div class="zhongyi-box">
                <div class="zhongyi-box-head">餐后饱胀</div>
                <div class="zhongyi-box-slider">
                  <el-form-item prop="pre_degree_after_meal_satiety" ref="pre_degree_after_meal_satiety"
                    style="margin-bottom: 0;">
                    <el-slider style="width: 225px" v-model="form.pre_degree_after_meal_satiety" show-stops :min="0"
                      :max="5" :marks="{ 0: '', 1: '', 2: '', 3: '', 4: '', 5: '' }" :show-tooltip="false">
                    </el-slider>
                  </el-form-item>
                  <div :style="style2" :class="
                    form.pre_degree_after_meal_satiety == 1
                      ? 'form-zhongyi-box-slider-cen-name1'
                      : 'form-zhongyi-box-slider-cen-name'
                  ">
                    {{ form.pre_degree_after_meal_satiety }}
                  </div>
                  <div :style="style2" class="form-zhongyi-box-slider-cen-zheng">
                    <span v-if="form.pre_degree_after_meal_satiety == 1">
                      无症状
                    </span>
                    <span v-if="form.pre_degree_after_meal_satiety == 2">
                      轻度
                    </span>
                    <span v-if="form.pre_degree_after_meal_satiety == 3">
                      中度
                    </span>
                    <span v-if="form.pre_degree_after_meal_satiety == 4">
                      严重
                    </span>
                    <span class="zhong-zheng5" v-if="form.pre_degree_after_meal_satiety == 5">
                      极严重
                    </span>
                  </div>
                </div>
              </div>
              <!-- 滑块 -->
              <!-- 滑块 -->
              <div class="zhongyi-box">
                <div class="zhongyi-box-head">早饱</div>
                <div class="zhongyi-box-slider">
                  <el-form-item prop="pre_degree_premature_satiety" ref="pre_degree_premature_satiety"
                    style="margin-bottom: 0;">
                    <el-slider class="zhongyi-box-slider-item" v-model="form.pre_degree_premature_satiety" show-stops
                      :min="0" :max="5" :marks="{ 0: '', 1: '', 2: '', 3: '', 4: '', 5: '' }" :show-tooltip="false">
                    </el-slider>
                  </el-form-item>
                  <div :style="style3" :class="
                    form.pre_degree_premature_satiety == 1
                      ? 'form-zhongyi-box-slider-cen-name1'
                      : 'form-zhongyi-box-slider-cen-name'
                  ">
                    {{ form.pre_degree_premature_satiety }}
                  </div>
                  <div :style="style3" class="form-zhongyi-box-slider-cen-zheng">
                    <span v-if="form.pre_degree_premature_satiety == 1">
                      无症状
                    </span>
                    <span v-if="form.pre_degree_premature_satiety == 2"> 轻度 </span>
                    <span v-if="form.pre_degree_premature_satiety == 3"> 中度 </span>
                    <span v-if="form.pre_degree_premature_satiety == 4"> 严重 </span>
                    <span class="zhong-zheng5" v-if="form.pre_degree_premature_satiety == 5">
                      极严重
                    </span>
                  </div>
                </div>
              </div>
              <!-- 滑块 -->
              <!-- 滑块 -->
              <div class="zhongyi-box">
                <div class="zhongyi-box-head">上腹烧灼感</div>
                <div class="zhongyi-box-slider">
                  <el-form-item prop="pre_degree_burn_upper_abdomen" ref="pre_degree_burn_upper_abdomen"
                    style="margin-bottom: 0;">
                    <el-slider class="zhongyi-box-slider-item" v-model="form.pre_degree_burn_upper_abdomen" show-stops
                      :min="0" :max="5" :marks="{ 0: '', 1: '', 2: '', 3: '', 4: '', 5: '' }" :show-tooltip="false">
                    </el-slider>
                  </el-form-item>
                  <div :style="style4" :class="
                    form.pre_degree_burn_upper_abdomen == 1
                      ? 'form-zhongyi-box-slider-cen-name1'
                      : 'form-zhongyi-box-slider-cen-name'
                  ">
                    {{ form.pre_degree_burn_upper_abdomen }}
                  </div>
                  <div :style="style4" class="form-zhongyi-box-slider-cen-zheng">
                    <span v-if="form.pre_degree_burn_upper_abdomen == 1">
                      无症状
                    </span>
                    <span v-if="form.pre_degree_burn_upper_abdomen == 2">
                      轻度
                    </span>
                    <span v-if="form.pre_degree_burn_upper_abdomen == 3">
                      中度
                    </span>
                    <span v-if="form.pre_degree_burn_upper_abdomen == 4">
                      严重
                    </span>
                    <span class="zhong-zheng5" v-if="form.pre_degree_burn_upper_abdomen == 5">
                      极严重
                    </span>
                  </div>
                </div>
              </div>
              <!-- 滑块 -->
              <div class="form-fenshu">
                <div class="form-fenshu-left">总分：</div>
                <div class="form-fenshu-right">
                  {{ pre_degree }}
                </div>
              </div>
            </div>
            <!-- 前 -->
            <div class="inform-box" style="margin-bottom: 10px">
              <div class="zhongyi-head">主要症状发生频率评分表</div>
              <div class="zhongyi-text">
                <div class="zhongyi-text-zhu">注：</div>
                <div class="zhongyi-text-name">
                  <div>1：无症状</div>
                  <div>2：轻度</div>
                  <div>3：中度</div>
                  <div>4：严重</div>
                  <div>5：极严重</div>
                </div>
              </div>
              <!-- 滑块 -->
              <div class="zhongyi-box">
                <div class="zhongyi-box-head">上腹痛</div>
                <div class="zhongyi-box-slider">
                  <el-form-item prop="pre_frequency_ep_pain" ref="pre_frequency_ep_pain" style="margin-bottom: 0;">
                    <el-slider style="width: 225px" v-model="form.pre_frequency_ep_pain" show-stops :min="0" :max="5"
                      :marks="{ 0: '', 1: '', 2: '', 3: '', 4: '', 5: '' }" :show-tooltip="false">
                    </el-slider>
                  </el-form-item>
                  <div :style="style5" :class="
                    form.pre_frequency_ep_pain == 1
                      ? 'form-zhongyi-box-slider-cen-name1'
                      : 'form-zhongyi-box-slider-cen-name'
                  ">
                    {{ form.pre_frequency_ep_pain }}
                  </div>
                  <div :style="style5" class="form-zhongyi-box-slider-cen-zheng">
                    <span v-if="form.pre_frequency_ep_pain == 1">
                      无症状
                    </span>
                    <span v-if="form.pre_frequency_ep_pain == 2">
                      轻度
                    </span>
                    <span v-if="form.pre_frequency_ep_pain == 3">
                      中度
                    </span>
                    <span v-if="form.pre_frequency_ep_pain == 4">
                      严重
                    </span>
                    <span class="zhong-zheng5" v-if="form.pre_frequency_ep_pain == 5">
                      极严重
                    </span>
                  </div>
                </div>
              </div>
              <!-- 滑块 -->
              <!-- 滑块 -->
              <div class="zhongyi-box">
                <div class="zhongyi-box-head">餐后饱胀</div>
                <div class="zhongyi-box-slider">
                  <el-form-item prop="pre_frequency_after_meal_satiety" ref="pre_frequency_after_meal_satiety"
                    style="margin-bottom: 0;">
                    <el-slider style="width: 225px" v-model="form.pre_frequency_after_meal_satiety" show-stops :min="0"
                      :max="5" :marks="{ 0: '', 1: '', 2: '', 3: '', 4: '', 5: '' }" :show-tooltip="false">
                    </el-slider>
                  </el-form-item>
                  <div :style="style6" :class="
                    form.pre_frequency_after_meal_satiety == 1
                      ? 'form-zhongyi-box-slider-cen-name1'
                      : 'form-zhongyi-box-slider-cen-name'
                  ">
                    {{ form.pre_frequency_after_meal_satiety }}
                  </div>
                  <div :style="style6" class="form-zhongyi-box-slider-cen-zheng">
                    <span v-if="form.pre_frequency_after_meal_satiety == 1">
                      无症状
                    </span>
                    <span v-if="form.pre_frequency_after_meal_satiety == 2">
                      轻度
                    </span>
                    <span v-if="form.pre_frequency_after_meal_satiety == 3">
                      中度
                    </span>
                    <span v-if="form.pre_frequency_after_meal_satiety == 4">
                      严重
                    </span>
                    <span class="zhong-zheng5" v-if="form.pre_frequency_after_meal_satiety == 5">
                      极严重
                    </span>
                  </div>
                </div>
              </div>
              <!-- 滑块 -->
              <!-- 滑块 -->
              <div class="zhongyi-box">
                <div class="zhongyi-box-head">早饱</div>
                <div class="zhongyi-box-slider">
                  <el-form-item prop="pre_frequency_premature_satiety" ref="pre_frequency_premature_satiety"
                    style="margin-bottom: 0;">
                    <el-slider class="zhongyi-box-slider-item" v-model="form.pre_frequency_premature_satiety" show-stops
                      :min="0" :max="5" :marks="{ 0: '', 1: '', 2: '', 3: '', 4: '', 5: '' }" :show-tooltip="false">
                    </el-slider>
                  </el-form-item>
                  <div :style="style7" :class="
                    form.pre_frequency_premature_satiety == 1
                      ? 'form-zhongyi-box-slider-cen-name1'
                      : 'form-zhongyi-box-slider-cen-name'
                  ">
                    {{ form.pre_frequency_premature_satiety }}
                  </div>
                  <div :style="style7" class="form-zhongyi-box-slider-cen-zheng">
                    <span v-if="form.pre_frequency_premature_satiety == 1">
                      无症状
                    </span>
                    <span v-if="form.pre_frequency_premature_satiety == 2"> 轻度 </span>
                    <span v-if="form.pre_frequency_premature_satiety == 3"> 中度 </span>
                    <span v-if="form.pre_frequency_premature_satiety == 4"> 严重 </span>
                    <span class="zhong-zheng5" v-if="form.pre_frequency_premature_satiety == 5">
                      极严重
                    </span>
                  </div>
                </div>
              </div>
              <!-- 滑块 -->
              <!-- 滑块 -->
              <div class="zhongyi-box">
                <div class="zhongyi-box-head">上腹烧灼感</div>
                <div class="zhongyi-box-slider">
                  <el-form-item prop="pre_frequency_burn_upper_abdomen" ref="pre_frequency_burn_upper_abdomen"
                    style="margin-bottom: 0;">
                    <el-slider class="zhongyi-box-slider-item" v-model="form.pre_frequency_burn_upper_abdomen" show-stops
                      :min="0" :max="5" :marks="{ 0: '', 1: '', 2: '', 3: '', 4: '', 5: '' }" :show-tooltip="false">
                    </el-slider>
                  </el-form-item>
                  <div :style="style8" :class="
                    form.pre_frequency_burn_upper_abdomen == 1
                      ? 'form-zhongyi-box-slider-cen-name1'
                      : 'form-zhongyi-box-slider-cen-name'
                  ">
                    {{ form.pre_frequency_burn_upper_abdomen }}
                  </div>
                  <div :style="style8" class="form-zhongyi-box-slider-cen-zheng">
                    <span v-if="form.pre_frequency_burn_upper_abdomen == 1">
                      无症状
                    </span>
                    <span v-if="form.pre_frequency_burn_upper_abdomen == 2">
                      轻度
                    </span>
                    <span v-if="form.pre_frequency_burn_upper_abdomen == 3">
                      中度
                    </span>
                    <span v-if="form.pre_frequency_burn_upper_abdomen == 4">
                      严重
                    </span>
                    <span class="zhong-zheng5" v-if="form.pre_frequency_burn_upper_abdomen == 5">
                      极严重
                    </span>
                  </div>
                </div>
              </div>
              <!-- 滑块 -->
              <div class="form-fenshu">
                <div class="form-fenshu-left">总分：</div>
                <div class="form-fenshu-right">
                  {{ pre_frequency }}
                </div>
              </div>
            </div>
            <!-- 其他症状评分 -->
            <div class="inform-box">
              <div>其他症状评分</div>
              <el-form-item prop="pre_other_acid_regurgitation" ref="pre_other_acid_regurgitation" label="反酸："
                style="margin: 30px 0 0 0" class="xinde-radio-qita">
                <el-radio-group v-model="form.pre_other_acid_regurgitation">
                  <el-radio :label="1">无</el-radio>
                  <el-radio :label="2">偶尔</el-radio>
                  <el-radio :label="3">有时</el-radio>
                  <el-radio :label="4">经常</el-radio>
                  <el-radio :label="5">总是</el-radio>
                </el-radio-group>
              </el-form-item>
              <el-form-item prop="pre_other_heartburn" ref="pre_other_heartburn" label="烧心：" style="margin: 30px 0 0 0"
                class="xinde-radio-qita">
                <el-radio-group v-model="form.pre_other_heartburn">
                  <el-radio :label="1">无</el-radio>
                  <el-radio :label="2">偶尔</el-radio>
                  <el-radio :label="3">有时</el-radio>
                  <el-radio :label="4">经常</el-radio>
                  <el-radio :label="5">总是</el-radio>
                </el-radio-group>
              </el-form-item>
              <el-form-item prop="pre_other_shit" ref="pre_other_shit" label="大便：" style="margin: 30px 0 0 0"
                class="xinde-radio-qita">
                <el-radio-group v-model="form.pre_other_shit">
                  <el-radio :label="1">正常</el-radio>
                  <el-radio :label="2">不正常</el-radio>
                </el-radio-group>
              </el-form-item>
              <el-form-item prop="pre_other_fatigued" ref="pre_other_fatigued" label="疲乏：" style="margin: 30px 0 0 0"
                class="xinde-radio-qita">
                <el-radio-group v-model="form.pre_other_fatigued">
                  <el-radio :label="1">无</el-radio>
                  <el-radio :label="2">轻微</el-radio>
                  <el-radio :label="3">中等</el-radio>
                  <el-radio :label="4">重度</el-radio>
                </el-radio-group>
              </el-form-item>
              <el-form-item prop="pre_other_poor_sleep" ref="pre_other_poor_sleep" label="睡眠差：" style="margin: 30px 0 0 0"
                class="xinde-radio-qita">
                <el-radio-group v-model="form.pre_other_poor_sleep">
                  <el-radio :label="1">无</el-radio>
                  <el-radio :label="2">轻微</el-radio>
                  <el-radio :label="3">中等</el-radio>
                  <el-radio :label="4">重度</el-radio>
                </el-radio-group>
              </el-form-item>
              <el-form-item prop="pre_other_irritability" ref="pre_other_irritability" label="烦躁易怒："
                style="margin: 30px 0 0 0" class="xinde-radio-qita">
                <el-radio-group v-model="form.pre_other_irritability">
                  <el-radio :label="1">无</el-radio>
                  <el-radio :label="2">偶尔</el-radio>
                  <el-radio :label="3">有时</el-radio>
                  <el-radio :label="4">经常</el-radio>
                  <el-radio :label="5">总是</el-radio>
                </el-radio-group>
              </el-form-item>
              <el-form-item prop="pre_other_anxiety" ref="pre_other_anxiety" label="焦虑：" style="margin: 30px 0 0 0"
                class="xinde-radio-qita">
                <el-radio-group v-model="form.pre_other_anxiety">
                  <el-radio :label="1">无</el-radio>
                  <el-radio :label="2">轻微</el-radio>
                  <el-radio :label="3">中等</el-radio>
                  <el-radio :label="4">重度</el-radio>
                </el-radio-group>
              </el-form-item>
              <el-form-item prop="pre_other_depressed" ref="pre_other_depressed" label="抑郁：" style="margin: 30px 0 0 0"
                class="xinde-radio-qita">
                <el-radio-group v-model="form.pre_other_depressed">
                  <el-radio :label="1">无</el-radio>
                  <el-radio :label="2">轻微</el-radio>
                  <el-radio :label="3">中等</el-radio>
                  <el-radio :label="4">重度</el-radio>
                </el-radio-group>
              </el-form-item>
              <div class="qita-tishi">
                <div class="qita-tishi-title">*提示：</div>
                <div class="qita-tishi-con">
                  <div class="qita-tishi-con-h">程度轻微：</div>
                  <div class="qita-tishi-con-t">有症状，但不影响工作和生活</div>
                </div>
                <div class="qita-tishi-con">
                  <div class="qita-tishi-con-h">程度中等：</div>
                  <div class="qita-tishi-con-t">影响部分工作和生活，休息可缓解，不需治疗</div>
                </div>
                <div class="qita-tishi-con">
                  <div class="qita-tishi-con-h">程度严重：</div>
                  <div class="qita-tishi-con-t">程度重，影响工作生活，休息不能缓解，需治疗</div>
                </div>
              </div>
            </div>
          </div>
          <!-- 治疗方案-->
          <div style="margin-top: 20px" ref="treatmentref">
            <div class="index-title">
              <div class="index-title-img">
                <img src="@/assets/image/questionnaire/组 812@2x.png" />
              </div>
              <div class="index-title-name">治疗方案</div>
              <div class="index-title-xia" style="width: 65px"></div>
            </div>
            <div class="inform-box">
              <div class="inform-box-title" style="margin-bottom: 15px;">用药记录：
                <span style="color: red;font-size: 12px;s">如果没有使用某药物，填【无】</span>
              </div>
              <!-- <el-form-item style="margin-bottom: 0">   -->
              <el-checkbox-group v-model="form.medical_record_checkbox" class="zhiliaofangan-group">
                <!-- <div class="zhiliaofangan-group-item">
                  <el-checkbox label="1" name="type" @change="check_true_ppi">PPI/Pcab：</el-checkbox>
                  <el-form-item prop="ppi_input" ref="ppi_input" class="inform-box-lable"
                    style="margin-bottom: 5px;margin-top: 10px;">
                    <el-input :disabled="form.medical_record_checkbox.includes('1') == false" v-model="form.ppi_input"
                      placeholder="请输入内容" />
                  </el-form-item>
                  <el-form-item prop="ppi_usage_dosage" ref="ppi_usage_dosage" label="用法用量：" class="inform-box-lable"
                    style="margin-bottom: 0">
                    <el-input :disabled="form.medical_record_checkbox.includes('1') == false"
                      v-model="form.ppi_usage_dosage" placeholder="请输入内容" />
                  </el-form-item>
                </div>
                <div class="zhiliaofangan-group-item">
                  <el-checkbox label="2" name="type" @change="check_true_miji">铋剂：</el-checkbox>
                  <el-form-item prop="bismuth_input" ref="bismuth_input" class="inform-box-lable"
                    style="margin-bottom: 5px;margin-top: 10px;">
                    <el-input :disabled="form.medical_record_checkbox.includes('2') == false" v-model="form.bismuth_input"
                      placeholder="请输入内容" />
                  </el-form-item>
                  <el-form-item prop="bismuth_usage_dosage" ref="bismuth_usage_dosage" label="用法用量："
                    class="inform-box-lable" style="margin-bottom: 0">
                    <el-input :disabled="form.medical_record_checkbox.includes('2') == false"
                      v-model="form.bismuth_usage_dosage" placeholder="请输入内容" />
                  </el-form-item>
                </div>
                <div class="zhiliaofangan-group-item">
                  <el-checkbox label="3" name="type" @change="check_true_kang1">抗生素1：</el-checkbox>
                  <el-form-item prop="antibiotic1_input" ref="antibiotic1_input" class="inform-box-lable"
                    style="margin-bottom: 5px;margin-top: 10px;">
                    <el-input :disabled="form.medical_record_checkbox.includes('3') == false"
                      v-model="form.antibiotic1_input" placeholder="请输入内容" />
                  </el-form-item>
                  <el-form-item prop="antibiotic1_usage_dosage" ref="antibiotic1_usage_dosage" label="用法用量："
                    class="inform-box-lable" style="margin-bottom: 0">
                    <el-input :disabled="form.medical_record_checkbox.includes('3') == false"
                      v-model="form.antibiotic1_usage_dosage" placeholder="请输入内容" />
                  </el-form-item>
                </div>
                <div class="zhiliaofangan-group-item">
                  <el-checkbox label="4" name="type" @change="check_true_kang2">抗生素2：</el-checkbox>
                  <el-form-item prop="antibiotic2_input" ref="antibiotic2_input" class="inform-box-lable"
                    style="margin-bottom: 5px;margin-top: 10px;">
                    <el-input :disabled="form.medical_record_checkbox.includes('4') == false"
                      v-model="form.antibiotic2_input" placeholder="请输入内容" />
                  </el-form-item>
                  <el-form-item prop="antibiotic2_usage_dosage" ref="antibiotic2_usage_dosage" label="用法用量："
                    class="inform-box-lable" style="margin-bottom: 0">
                    <el-input :disabled="form.medical_record_checkbox.includes('4') == false"
                      v-model="form.antibiotic2_usage_dosage" placeholder="请输入内容" />
                  </el-form-item>
                </div> -->

                <div class="zhiliaofangan-group-item">
                  <el-form-item label="PPI/Pcab：" prop="ppi_input" ref="ppi_input" class="inform-box-lable"
                    style="margin-bottom: 5px">
                    <el-input v-model="form.ppi_input" placeholder="请输入内容" />
                  </el-form-item>
                  <el-form-item label="用法用量：" prop="ppi_usage_dosage" ref="ppi_usage_dosage" class="inform-box-lable"
                    style="margin-bottom: 0">
                    <el-input v-model="form.ppi_usage_dosage" placeholder="请输入内容" />
                  </el-form-item>
                </div>

                <div class="zhiliaofangan-group-item">
                  <el-form-item label="铋剂：" prop="bismuth_input" ref="bismuth_input" class="inform-box-lable"
                    style="margin-bottom: 5px">
                    <el-input v-model="form.bismuth_input" placeholder="请输入内容" />
                  </el-form-item>
                  <el-form-item label="用法用量：" prop="bismuth_usage_dosage" ref="bismuth_usage_dosage"
                    class="inform-box-lable" style="margin-bottom: 0">
                    <el-input v-model="form.bismuth_usage_dosage" placeholder="请输入内容" />
                  </el-form-item>
                </div>

                <div class="zhiliaofangan-group-item">
                  <el-form-item label="抗生素1：" prop="antibiotic1_input" ref="antibiotic1_input" class="inform-box-lable"
                    style="margin-bottom: 5px">
                    <el-input v-model="form.antibiotic1_input" placeholder="请输入内容" />
                  </el-form-item>
                  <el-form-item label="用法用量：" prop="antibiotic1_usage_dosage" ref="antibiotic1_usage_dosage"
                    class="inform-box-lable" style="margin-bottom: 0">
                    <el-input v-model="form.antibiotic1_usage_dosage" placeholder="请输入内容" />
                  </el-form-item>
                </div>

                <div class="zhiliaofangan-group-item">
                  <el-form-item label="抗生素2：" prop="antibiotic2_input" ref="antibiotic2_input" class="inform-box-lable"
                    style="margin-bottom: 5px">
                    <el-input v-model="form.antibiotic2_input" placeholder="请输入内容" />
                  </el-form-item>
                  <el-form-item label="用法用量：" prop="antibiotic2_usage_dosage" ref="antibiotic2_usage_dosage"
                    class="inform-box-lable" style="margin-bottom: 0">
                    <el-input v-model="form.antibiotic2_usage_dosage" placeholder="请输入内容" />
                  </el-form-item>
                </div>
                <!-- @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@ -->
                <div class="zhiliaofangan-group-item">
                  <el-form-item label="荜铃胃痛颗粒用法用量：" prop="biling_granules_input" ref="biling_granules_input"
                    class="inform-box-lable" style="margin-bottom: 0">
                    <el-input v-model="form.biling_granules_input" placeholder="请输入内容" />
                  </el-form-item>
                  <!-- <el-form-item label="用法用量：" prop="biling_granules_usage_dosage" ref="biling_granules_usage_dosage"
                    class="inform-box-lable" style="margin-bottom: 0">
                    <el-input v-model="form.biling_granules_usage_dosage" placeholder="请输入内容" />
                  </el-form-item>  -->
                </div>
              </el-checkbox-group>
              <!-- </el-form-item> -->
              <!-- 开始服药时间 -->
              <el-form-item prop="start_taking_medicine" ref="start_taking_medicine" style="margin-bottom: 0px;">
                <div class="inform-box-buliang"
                  style="display: flex;align-items: center;justify-content: space-between;background-color: #fff;margin-top: 0;padding-bottom: 5px;">
                  <div style="width:110px; " class="form-xing-xin">
                    开始服药时间：
                  </div>
                  <el-date-picker style="background: #fff;" type="date" placeholder="选择日期"
                    v-model="form.start_taking_medicine"></el-date-picker>
                </div>
              </el-form-item>
              <!-- 停止服药时间： -->
              <el-form-item prop="stop_taking_medicine" ref="stop_taking_medicine" style="margin-bottom: 25px;">
                <div class="inform-box-buliang"
                  style="
                                                                                                                                                                                                                                                                                                                                                                                                                                  display: flex; 
                                                                                                                                                                                                                                                                                                                                                                                                                                  align-items: center;
                                                                                                                                                                                                                                                                                                                                                                                                                                  justify-content: space-between; 
                                                                                                                                                                                                                                                                                                                                                                                                                                  background-color: #fff;
                                                                                                                                                                                                                                                                                                                                                                                                                                  margin-top: 0;
                                                                                                                                                                                                                                                                                                                                                                                                                                  padding-bottom: 5px
                                                                                                                                                                                                                                                                                                                                                                                                                                  ;
                                                                                                                                                                                                                                                                                                                                                                                                                                ">
                  <div style="width:110px; " class="form-xing-xin">
                    停止服药时间：
                  </div>
                  <el-date-picker style="background: #fff;" type="date" placeholder="选择日期"
                    v-model="form.stop_taking_medicine"></el-date-picker>
                </div>
              </el-form-item>
              <!-- 治疗期间是否调整用药方案： -->
              <el-form-item label="治疗期间是否调整用药方案：" prop="adjust_medication" ref="adjust_medication">
                <el-radio-group v-model="form.adjust_medication">
                  <el-radio :label="1">是</el-radio>
                  <el-radio :label="2">否</el-radio>
                </el-radio-group>
              </el-form-item>
              <div v-if="form.adjust_medication == 1" class="inform-box-jiancha" style="padding-top: 0">
                <el-form-item prop="adjust_medication_reason" ref="adjust_medication_reason" style="margin-bottom: 0">
                  <div style="color: #606266">原因：</div>
                  <el-input v-model="form.adjust_medication_reason" placeholder="请输入内容" type="textarea" />
                </el-form-item>
              </div>
            </div>
          </div>
          <!-- 疗效评估-->
          <div style="margin-top: 20px" ref="evaluateref">
            <div>
              <div class="index-title">
                <div class="index-title-img">
                  <img src="@/assets/image/questionnaire/组 768@2x.png" />
                </div>
                <div class="index-title-name">疗效评估</div>
                <div class="index-title-xia" style="width: 65px"></div>
              </div>
              <!-- Hp 检查 -->
              <div class="inform-box" style="margin-bottom: 10px">
                <div class="inform-box-title">Hp 检查：</div>
                <!-- 检查日期 -->
                <el-form-item prop="after_hp_check_date" ref="after_hp_check_date">
                  <div class="inform-box-buliang"
                    style="display: flex; align-items: center;justify-content: space-between; background-color: #fff;margin-top: 0; padding-bottom: 5px;">
                    <div style="width:80px; " class="form-xing-xin">
                      检查日期：
                    </div>
                    <el-date-picker style="background: #fff;" type="date" placeholder="选择日期"
                      v-model="form.after_hp_check_date"></el-date-picker>
                  </div>
                </el-form-item>
                <div class="inform-box-input">
                  <el-form-item prop="after_hp_check_method" ref="after_hp_check_method" label="检查方法："
                    class="xinde-radio">
                    <el-radio-group v-model="form.after_hp_check_method">
                      <el-radio :label="1">13C‐UBT</el-radio>
                      <el-radio :label="2" style="margin-left: 38px;">14C‐UBT</el-radio>
                      <el-radio :label="3">快速尿素酶试验</el-radio>
                      <el-radio :label="4">胃黏膜组织RUT</el-radio>
                      <el-radio :label="5">其他</el-radio>
                    </el-radio-group>
                    <el-form-item prop="after_hp_check_method_input" ref="after_hp_check_method_input"
                      v-if="form.after_hp_check_method == 5">
                      <el-input class="form-input-xia" v-model="form.after_hp_check_method_input" placeholder="请输入内容"
                        style="left: 20%"></el-input>
                    </el-form-item>
                  </el-form-item>
                </div>
                <el-form-item prop="after_hp_check_result" ref="after_hp_check_result" label="检查结果："
                  style="margin: 35px 0 0 0" class="xinde-radio">
                  <el-radio-group v-model="form.after_hp_check_result">
                    <el-radio :label="1">Hp 阳性</el-radio>
                    <el-radio :label="2">Hp 阴性</el-radio>
                  </el-radio-group>
                </el-form-item>
              </div>
              <!-- 主要症状严重程度评分表 -->
              <div class="inform-box" style="margin-bottom: 10px">
                <div class="zhongyi-head">主要症状严重程度评分表</div>
                <div class="zhongyi-text">
                  <div class="zhongyi-text-zhu">注：</div>
                  <div class="zhongyi-text-name">
                    <div>1：无症状</div>
                    <div>2：轻度</div>
                    <div>3：中度</div>
                    <div>4：严重</div>
                    <div>5：极严重</div>
                  </div>
                </div>
                <!-- 滑块 -->
                <div class="zhongyi-box">
                  <div class="zhongyi-box-head">上腹痛</div>
                  <div class="zhongyi-box-slider">
                    <el-form-item prop="after_post_degree_ep_pain" ref="after_post_degree_ep_pain"
                      style="margin-bottom: 0;">
                      <el-slider style="width: 225px" v-model="form.after_post_degree_ep_pain" show-stops :min="0"
                        :max="5" :marks="{ 0: '', 1: '', 2: '', 3: '', 4: '', 5: '' }" :show-tooltip="false">
                      </el-slider>
                    </el-form-item>
                    <div :style="style9" :class="
                      form.after_post_degree_ep_pain == 1
                        ? 'form-zhongyi-box-slider-cen-name1'
                        : 'form-zhongyi-box-slider-cen-name'
                    ">
                      {{ form.after_post_degree_ep_pain }}
                    </div>
                    <div :style="style9" class="form-zhongyi-box-slider-cen-zheng">
                      <span v-if="form.after_post_degree_ep_pain == 1">
                        无症状
                      </span>
                      <span v-if="form.after_post_degree_ep_pain == 2">
                        轻度
                      </span>
                      <span v-if="form.after_post_degree_ep_pain == 3">
                        中度
                      </span>
                      <span v-if="form.after_post_degree_ep_pain == 4">
                        严重
                      </span>
                      <span class="zhong-zheng5" v-if="form.after_post_degree_ep_pain == 5">
                        极严重
                      </span>
                    </div>
                  </div>
                </div>
                <!-- 滑块 -->
                <!-- 滑块 -->
                <div class="zhongyi-box">
                  <div class="zhongyi-box-head">餐后饱胀</div>
                  <div class="zhongyi-box-slider">
                    <el-form-item prop="after_post_degree_after_meal_satiety" ref="after_post_degree_after_meal_satiety"
                      style="margin-bottom: 0;">
                      <el-slider style="width: 225px" v-model="form.after_post_degree_after_meal_satiety" show-stops
                        :min="0" :max="5" :marks="{ 0: '', 1: '', 2: '', 3: '', 4: '', 5: '' }" :show-tooltip="false">
                      </el-slider>
                    </el-form-item>
                    <div :style="style10" :class="
                      form.after_post_degree_after_meal_satiety == 1
                        ? 'form-zhongyi-box-slider-cen-name1'
                        : 'form-zhongyi-box-slider-cen-name'
                    ">
                      {{ form.after_post_degree_after_meal_satiety }}
                    </div>
                    <div :style="style10" class="form-zhongyi-box-slider-cen-zheng">
                      <span v-if="form.after_post_degree_after_meal_satiety == 1">
                        无症状
                      </span>
                      <span v-if="form.after_post_degree_after_meal_satiety == 2">
                        轻度
                      </span>
                      <span v-if="form.after_post_degree_after_meal_satiety == 3">
                        中度
                      </span>
                      <span v-if="form.after_post_degree_after_meal_satiety == 4">
                        严重
                      </span>
                      <span class="zhong-zheng5" v-if="form.after_post_degree_after_meal_satiety == 5">
                        极严重
                      </span>
                    </div>
                  </div>
                </div>
                <!-- 滑块 -->
                <!-- 滑块 -->
                <div class="zhongyi-box">
                  <div class="zhongyi-box-head">早饱</div>
                  <div class="zhongyi-box-slider">
                    <el-form-item prop="after_post_degree_premature_satiety" ref="after_post_degree_premature_satiety"
                      style="margin-bottom: 0;">
                      <el-slider class="zhongyi-box-slider-item" v-model="form.after_post_degree_premature_satiety"
                        show-stops :min="0" :max="5" :marks="{ 0: '', 1: '', 2: '', 3: '', 4: '', 5: '' }"
                        :show-tooltip="false">
                      </el-slider>
                    </el-form-item>
                    <div :style="style11" :class="
                      form.after_post_degree_premature_satiety == 1
                        ? 'form-zhongyi-box-slider-cen-name1'
                        : 'form-zhongyi-box-slider-cen-name'
                    ">
                      {{ form.after_post_degree_premature_satiety }}
                    </div>
                    <div :style="style11" class="form-zhongyi-box-slider-cen-zheng">
                      <span v-if="form.after_post_degree_premature_satiety == 1">
                        无症状
                      </span>
                      <span v-if="form.after_post_degree_premature_satiety == 2"> 轻度 </span>
                      <span v-if="form.after_post_degree_premature_satiety == 3"> 中度 </span>
                      <span v-if="form.after_post_degree_premature_satiety == 4"> 严重 </span>
                      <span class="zhong-zheng5" v-if="form.after_post_degree_premature_satiety == 5">
                        极严重
                      </span>
                    </div>
                  </div>
                </div>
                <!-- 滑块 -->
                <!-- 滑块 -->
                <div class="zhongyi-box">
                  <div class="zhongyi-box-head">上腹烧灼感</div>
                  <div class="zhongyi-box-slider">
                    <el-form-item prop="after_post_degree_burn_upper_abdomen" ref="after_post_degree_burn_upper_abdomen"
                      style="margin-bottom: 0;">
                      <el-slider class="zhongyi-box-slider-item" v-model="form.after_post_degree_burn_upper_abdomen"
                        show-stops :min="0" :max="5" :marks="{ 0: '', 1: '', 2: '', 3: '', 4: '', 5: '' }"
                        :show-tooltip="false">
                      </el-slider>
                    </el-form-item>
                    <div :style="style12" :class="
                      form.after_post_degree_burn_upper_abdomen == 1
                        ? 'form-zhongyi-box-slider-cen-name1'
                        : 'form-zhongyi-box-slider-cen-name'
                    ">
                      {{ form.after_post_degree_burn_upper_abdomen }}
                    </div>
                    <div :style="style12" class="form-zhongyi-box-slider-cen-zheng">
                      <span v-if="form.after_post_degree_burn_upper_abdomen == 1">
                        无症状
                      </span>
                      <span v-if="form.after_post_degree_burn_upper_abdomen == 2">
                        轻度
                      </span>
                      <span v-if="form.after_post_degree_burn_upper_abdomen == 3">
                        中度
                      </span>
                      <span v-if="form.after_post_degree_burn_upper_abdomen == 4">
                        严重
                      </span>
                      <span class="zhong-zheng5" v-if="form.after_post_degree_burn_upper_abdomen == 5">
                        极严重
                      </span>
                    </div>
                  </div>
                </div>
                <!-- 滑块 -->
                <div class="form-fenshu">
                  <div class="form-fenshu-left">总分：</div>
                  <div class="form-fenshu-right">
                    {{ degree_amount }}
                  </div>
                </div>
              </div>
              <!-- 主要症状发生频率评分表 -->
              <div class="inform-box" style="margin-bottom: 10px">
                <div class="zhongyi-head">主要症状发生频率评分表</div>
                <div class="zhongyi-text">
                  <div class="zhongyi-text-zhu">注：</div>
                  <div class="zhongyi-text-name">
                    <div>1：无症状</div>
                    <div>2：轻度</div>
                    <div>3：中度</div>
                    <div>4：严重</div>
                    <div>5：极严重</div>
                  </div>
                </div>
                <!-- 滑块 -->
                <div class="zhongyi-box">
                  <div class="zhongyi-box-head">上腹痛</div>
                  <div class="zhongyi-box-slider">
                    <el-form-item prop="after_post_frequency_ep_pain" ref="after_post_frequency_ep_pain"
                      style="margin-bottom: 0;">
                      <el-slider style="width: 225px" v-model="form.after_post_frequency_ep_pain" show-stops :min="0"
                        :max="5" :marks="{ 0: '', 1: '', 2: '', 3: '', 4: '', 5: '' }" :show-tooltip="false">
                      </el-slider>
                    </el-form-item>
                    <div :style="style13" :class="
                      form.after_post_frequency_ep_pain == 1
                        ? 'form-zhongyi-box-slider-cen-name1'
                        : 'form-zhongyi-box-slider-cen-name'
                    ">
                      {{ form.after_post_frequency_ep_pain }}
                    </div>
                    <div :style="style13" class="form-zhongyi-box-slider-cen-zheng">
                      <span v-if="form.after_post_frequency_ep_pain == 1">
                        无症状
                      </span>
                      <span v-if="form.after_post_frequency_ep_pain == 2">
                        轻度
                      </span>
                      <span v-if="form.after_post_frequency_ep_pain == 3">
                        中度
                      </span>
                      <span v-if="form.after_post_frequency_ep_pain == 4">
                        严重
                      </span>
                      <span class="zhong-zheng5" v-if="form.after_post_frequency_ep_pain == 5">
                        极严重
                      </span>
                    </div>
                  </div>
                </div>
                <!-- 滑块 -->
                <!-- 滑块 -->
                <div class="zhongyi-box">
                  <div class="zhongyi-box-head">餐后饱胀</div>
                  <div class="zhongyi-box-slider">
                    <el-form-item prop="after_post_frequency_after_meal_satiety"
                      ref="after_post_frequency_after_meal_satiety" style="margin-bottom: 0;">
                      <el-slider style="width: 225px" v-model="form.after_post_frequency_after_meal_satiety" show-stops
                        :min="0" :max="5" :marks="{ 0: '', 1: '', 2: '', 3: '', 4: '', 5: '' }" :show-tooltip="false">
                      </el-slider>
                    </el-form-item>
                    <div :style="style14" :class="
                      form.after_post_frequency_after_meal_satiety == 1
                        ? 'form-zhongyi-box-slider-cen-name1'
                        : 'form-zhongyi-box-slider-cen-name'
                    ">
                      {{ form.after_post_frequency_after_meal_satiety }}
                    </div>
                    <div :style="style14" class="form-zhongyi-box-slider-cen-zheng">
                      <span v-if="form.after_post_frequency_after_meal_satiety == 1">
                        无症状
                      </span>
                      <span v-if="form.after_post_frequency_after_meal_satiety == 2">
                        轻度
                      </span>
                      <span v-if="form.after_post_frequency_after_meal_satiety == 3">
                        中度
                      </span>
                      <span v-if="form.after_post_frequency_after_meal_satiety == 4">
                        严重
                      </span>
                      <span class="zhong-zheng5" v-if="form.after_post_frequency_after_meal_satiety == 5">
                        极严重
                      </span>
                    </div>
                  </div>
                </div>
                <!-- 滑块 -->
                <!-- 滑块 -->
                <div class="zhongyi-box">
                  <div class="zhongyi-box-head">早饱</div>
                  <div class="zhongyi-box-slider">
                    <el-form-item prop="after_post_frequency_premature_satiety"
                      ref="after_post_frequency_premature_satiety" style="margin-bottom: 0;">
                      <el-slider class="zhongyi-box-slider-item" v-model="form.after_post_frequency_premature_satiety"
                        show-stops :min="0" :max="5" :marks="{ 0: '', 1: '', 2: '', 3: '', 4: '', 5: '' }"
                        :show-tooltip="false">
                      </el-slider>
                    </el-form-item>
                    <div :style="style15" :class="
                      form.after_post_frequency_premature_satiety == 1
                        ? 'form-zhongyi-box-slider-cen-name1'
                        : 'form-zhongyi-box-slider-cen-name'
                    ">
                      {{ form.after_post_frequency_premature_satiety }}
                    </div>
                    <div :style="style15" class="form-zhongyi-box-slider-cen-zheng">
                      <span v-if="form.after_post_frequency_premature_satiety == 1">
                        无症状
                      </span>
                      <span v-if="form.after_post_frequency_premature_satiety == 2"> 轻度 </span>
                      <span v-if="form.after_post_frequency_premature_satiety == 3"> 中度 </span>
                      <span v-if="form.after_post_frequency_premature_satiety == 4"> 严重 </span>
                      <span class="zhong-zheng5" v-if="form.after_post_frequency_premature_satiety == 5">
                        极严重
                      </span>
                    </div>
                  </div>
                </div>
                <!-- 滑块 -->
                <!-- 滑块 -->
                <div class="zhongyi-box">
                  <div class="zhongyi-box-head">上腹烧灼感</div>
                  <div class="zhongyi-box-slider">
                    <el-form-item prop="after_post_frequency_burn_upper_abdomen"
                      ref="after_post_frequency_burn_upper_abdomen" style="margin-bottom: 0;">
                      <el-slider class="zhongyi-box-slider-item" v-model="form.after_post_frequency_burn_upper_abdomen"
                        show-stops :min="0" :max="5" :marks="{ 0: '', 1: '', 2: '', 3: '', 4: '', 5: '' }"
                        :show-tooltip="false">
                      </el-slider>
                    </el-form-item>
                    <div :style="style16" :class="
                      form.after_post_frequency_burn_upper_abdomen == 1
                        ? 'form-zhongyi-box-slider-cen-name1'
                        : 'form-zhongyi-box-slider-cen-name'
                    ">
                      {{ form.after_post_frequency_burn_upper_abdomen }}
                    </div>
                    <div :style="style16" class="form-zhongyi-box-slider-cen-zheng">
                      <span v-if="form.after_post_frequency_burn_upper_abdomen == 1">
                        无症状
                      </span>
                      <span v-if="form.after_post_frequency_burn_upper_abdomen == 2">
                        轻度
                      </span>
                      <span v-if="form.after_post_frequency_burn_upper_abdomen == 3">
                        中度
                      </span>
                      <span v-if="form.after_post_frequency_burn_upper_abdomen == 4">
                        严重
                      </span>
                      <span class="zhong-zheng5" v-if="form.after_post_frequency_burn_upper_abdomen == 5">
                        极严重
                      </span>
                    </div>
                  </div>
                </div>
                <!-- 滑块 -->
                <div class="form-fenshu">
                  <div class="form-fenshu-left">总分：</div>
                  <div class="form-fenshu-right">
                    {{ frequency_amount }}
                  </div>
                </div>
              </div>
              <!-- 其他症状评分 -->
              <div class="inform-box">
                <div>其他症状评分</div>
                <el-form-item prop="after_other_acid_regurgitation" ref="after_other_acid_regurgitation" label="反酸："
                  style="margin: 30px 0 0 0" class="xinde-radio-qita">
                  <el-radio-group v-model="form.after_other_acid_regurgitation">
                    <el-radio :label="1">无</el-radio>
                    <el-radio :label="2">偶尔</el-radio>
                    <el-radio :label="3">有时</el-radio>
                    <el-radio :label="4">经常</el-radio>
                    <el-radio :label="5">总是</el-radio>
                  </el-radio-group>
                </el-form-item>
                <el-form-item prop="after_other_heartburn" ref="after_other_heartburn" label="烧心："
                  style="margin: 30px 0 0 0" class="xinde-radio-qita">
                  <el-radio-group v-model="form.after_other_heartburn">
                    <el-radio :label="1">无</el-radio>
                    <el-radio :label="2">偶尔</el-radio>
                    <el-radio :label="3">有时</el-radio>
                    <el-radio :label="4">经常</el-radio>
                    <el-radio :label="5">总是</el-radio>
                  </el-radio-group>
                </el-form-item>
                <el-form-item prop="after_other_shit" ref="after_other_shit" label="大便：" style="margin: 30px 0 0 0"
                  class="xinde-radio-qita">
                  <el-radio-group v-model="form.after_other_shit">
                    <el-radio :label="1">正常</el-radio>
                    <el-radio :label="2">不正常</el-radio>
                  </el-radio-group>
                </el-form-item>
                <el-form-item prop="after_other_fatigued" ref="after_other_fatigued" label="疲乏："
                  style="margin: 30px 0 0 0" class="xinde-radio-qita">
                  <el-radio-group v-model="form.after_other_fatigued">
                    <el-radio :label="1">无</el-radio>
                    <el-radio :label="2">轻微</el-radio>
                    <el-radio :label="3">中等</el-radio>
                    <el-radio :label="4">重度</el-radio>
                  </el-radio-group>
                </el-form-item>
                <el-form-item prop="after_other_poor_sleep" ref="after_other_poor_sleep" label="睡眠差："
                  style="margin: 30px 0 0 0" class="xinde-radio-qita">
                  <el-radio-group v-model="form.after_other_poor_sleep">
                    <el-radio :label="1">无</el-radio>
                    <el-radio :label="2">轻微</el-radio>
                    <el-radio :label="3">中等</el-radio>
                    <el-radio :label="4">重度</el-radio>
                  </el-radio-group>
                </el-form-item>
                <el-form-item prop="after_other_irritability" ref="after_other_irritability" label="烦躁易怒："
                  style="margin: 30px 0 0 0" class="xinde-radio-qita">
                  <el-radio-group v-model="form.after_other_irritability">
                    <el-radio :label="1">无</el-radio>
                    <el-radio :label="2">偶尔</el-radio>
                    <el-radio :label="3">有时</el-radio>
                    <el-radio :label="4">经常</el-radio>
                    <el-radio :label="5">总是</el-radio>
                  </el-radio-group>
                </el-form-item>
                <el-form-item prop="after_other_anxiety" ref="after_other_anxiety" label="焦虑：" style="margin: 30px 0 0 0"
                  class="xinde-radio-qita">
                  <el-radio-group v-model="form.after_other_anxiety">
                    <el-radio :label="1">无</el-radio>
                    <el-radio :label="2">轻微</el-radio>
                    <el-radio :label="3">中等</el-radio>
                    <el-radio :label="4">重度</el-radio>
                  </el-radio-group>
                </el-form-item>
                <el-form-item prop="after_other_depressed" ref="after_other_depressed" label="抑郁："
                  style="margin: 30px 0 0 0" class="xinde-radio-qita">
                  <el-radio-group v-model="form.after_other_depressed">
                    <el-radio :label="1">无</el-radio>
                    <el-radio :label="2">轻微</el-radio>
                    <el-radio :label="3">中等</el-radio>
                    <el-radio :label="4">重度</el-radio>
                  </el-radio-group>
                </el-form-item>
                <div class="qita-tishi">
                  <div class="qita-tishi-title">*提示：</div>
                  <div class="qita-tishi-con">
                    <div class="qita-tishi-con-h">程度轻微：</div>
                    <div class="qita-tishi-con-t">有症状，但不影响工作和生活</div>
                  </div>
                  <div class="qita-tishi-con">
                    <div class="qita-tishi-con-h">程度中等：</div>
                    <div class="qita-tishi-con-t">影响部分工作和生活，休息可缓解，不需治疗</div>
                  </div>
                  <div class="qita-tishi-con">
                    <div class="qita-tishi-con-h">程度严重：</div>
                    <div class="qita-tishi-con-t">程度重，影响工作生活，休息不能缓解，需治疗</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- 不良反应检测  -->
          <div style="margin-top: 20px" ref="adversereactionref">
            <div class="index-title">
              <div class="index-title-img">
                <img src="@/assets/image/questionnaire/组 815@2x.png" />
              </div>
              <div class="index-title-name">不良反应检测</div>
              <div class="index-title-xia" style="width: 95px"></div>
            </div>
            <!-- 患者是否产生不良反应 -->
            <div class="inform-box" style="margin-top: 10px">
              <el-form-item label="患者是否产生不良反应：" style="margin: 8px 0 0 0" prop="adversereaction" ref="adversereaction">
                <el-radio-group v-model="form.adversereaction">
                  <el-radio :label="1">无</el-radio>
                  <el-radio :label="2">有</el-radio>
                </el-radio-group>
              </el-form-item>
              <div v-if="form.adversereaction == 2">
                <!-- 不良反应表现 -->
                <div class="inform-box-buliang inform-box-xiahuaxian" style="padding-bottom: 0px">
                  <el-form-item label="不良反应表现：" style="margin: 8px 0 0 0" prop="adversereaction_group"
                    ref="adversereaction_group">
                    <el-checkbox-group v-model="form.adversereaction_group" style="margin-top: 10px" class="buliang">
                      <el-checkbox label="1" name="type">头痛</el-checkbox>
                      <el-checkbox label="2" name="type">头晕</el-checkbox>
                      <el-checkbox label="3" name="type">恶心</el-checkbox>
                      <el-checkbox label="4" name="type">呕吐</el-checkbox>
                      <el-checkbox label="5" name="type">腹泻</el-checkbox>
                      <el-checkbox label="6" name="type">肌痛</el-checkbox>
                      <el-checkbox label="8" name="type">骨痛</el-checkbox>
                      <el-checkbox label="9" name="type">背痛</el-checkbox>
                      <el-checkbox label="10" name="type">寒颤</el-checkbox>
                      <el-checkbox label="11" name="type">疲劳</el-checkbox>
                      <el-checkbox label="12" name="type">乏力</el-checkbox>
                      <el-checkbox label="13" name="type">疼痛</el-checkbox>
                      <el-checkbox label="7" name="type">关节痛</el-checkbox>
                      <el-checkbox label="14" name="type">肢体疼痛</el-checkbox>
                      <el-checkbox label="15" name="type">流感样疾病</el-checkbox>
                      <el-checkbox label="16" name="type">其他</el-checkbox>
                    </el-checkbox-group>
                    <el-form-item class="huise" prop="adversereaction_group_input" ref="adversereaction_group_input"
                      v-if="form.adversereaction_group.includes('16') == true">
                      <el-input class="form-input-xia" v-model="form.adversereaction_group_input" placeholder="请输入内容"
                        style="
                                                                                                                                                                                                                                                                                                                                                                                                                                      position: absolute;
                                                                                                                                                                                                                                                                                                                                                                                                                                      left: 65px;
                                                                                                                                                                                                                                                                                                                                                                                                                                      top: -35px;
                                                                                                                                                                                                                                                                                                                                                                                                                                      width: 160px;
                                                                                                                                                                                                                                                                                                                                                                                                                                    "></el-input>
                    </el-form-item>
                  </el-form-item>
                </div>
                <!-- 反应发生日期 -->
                <el-form-item prop="adversereaction_date" ref="adversereaction_date">
                  <div class="inform-box-buliang"
                    style="
                                                                                                                                                                                                                                                                                                                                                                                                                                  display: flex;
                                                                                                                                                                                                                                                                                                                                                                                                                                  align-items: center;
                                                                                                                                                                                                                                                                                                                                                                                                                                  justify-content: space-between;
                                                                                                                                                                                                                                                                                                                                                                                                                                ">
                    <div style="width: 110px; margin-left: 5px" class="form-xing">
                      反应发生日期：
                    </div>
                    <el-date-picker type="date" placeholder="选择日期" v-model="form.adversereaction_date"></el-date-picker>
                  </div>
                </el-form-item>
                <!-- 是否用药后立即发生： -->
                <div class="inform-box-buliang">
                  <el-form-item label="是否用药后立即发生：" style="margin: 8px 0 0 0" prop="adversereaction_immediately"
                    ref="adversereaction_immediately">
                    <el-radio-group v-model="form.adversereaction_immediately">
                      <el-radio :label="1">是</el-radio>
                      <el-radio :label="2">否</el-radio>
                    </el-radio-group>
                  </el-form-item>
                </div>
                <!-- 反应发生时间，用药后几小时： -->
                <div class="inform-box-buliang" style="padding-top: 5px">
                  <el-form-item label="反应发生时间，用药后几小时：" class="inform-box-lable" style="margin-bottom: 0"
                    prop="adversereaction_time_input" ref="adversereaction_time_input">
                    <el-input v-model="form.adversereaction_time_input" placeholder="请输入反应发生时间，用药后几小时" type="textarea" />
                  </el-form-item>
                </div>
                <!-- 不良反应的严重程度： -->
                <div class="inform-box-buliang">
                  <el-form-item label="不良反应的严重程度：" style="margin: 8px 0 0 0" prop="adversereaction_degree"
                    ref="adversereaction_degree">
                    <el-radio-group v-model="form.adversereaction_degree">
                      <el-radio :label="1">轻度</el-radio>
                      <el-radio :label="2">中度</el-radio>
                      <el-radio :label="3">重度</el-radio>
                    </el-radio-group>
                  </el-form-item>
                </div>
                <!-- 是否与观察药物相关： -->
                <div class="inform-box-buliang">
                  <el-form-item label="是否与观察药物相关：" style="margin: 8px 0 0 0" prop="adversereaction_related"
                    ref="adversereaction_related">
                    <el-radio-group v-model="form.adversereaction_related">
                      <el-radio :label="1">肯定</el-radio>
                      <el-radio :label="2">很可能</el-radio>
                      <el-radio :label="3">可能</el-radio>
                      <el-radio :label="4">可能无关</el-radio>
                      <el-radio :label="5">待评价</el-radio>
                      <el-radio :label="6">无法评价</el-radio>
                    </el-radio-group>
                  </el-form-item>
                </div>
              </div>
            </div>
            <!-- <div class="inform-b-bgc">
              <div>AE程度：</div>
            </div> -->
            <div class="qita-tishi">
              <div class="qita-tishi-title">*AE程度：</div>
              <div class="qita-tishi-con">
                <div class="qita-tishi-con-h" style="width:42px ;">轻度：</div>
                <div class="qita-tishi-con-t">受试者可忍受，不影响治疗，不需要特别处理，对受试者康复无影响。</div>
              </div>
              <div class="qita-tishi-con">
                <div class="qita-tishi-con-h" style="width:42px ;">中度：</div>
                <div class="qita-tishi-con-t">受试者难以忍受，需要撤药中止试验或做特殊处理，对受试者康复有直接影响。</div>
              </div>
              <div class="qita-tishi-con">
                <div class="qita-tishi-con-h" style="width:42px ;">重度：</div>
                <div class="qita-tishi-con-t">危及受试者生命，致死或致残，需立即撤药或做紧急处理。凡严重不良事件请填写严重不良事件报告表，并按规定程序报告。</div>
              </div>
            </div>
            <!--  -->
          </div>
          <!-- 病例照片附件 -->
          <div style="margin-top: 20px" ref="uploadref">
            <div class="index-title">
              <div class="index-title-img">
                <img src="@/assets/image/questionnaire/shangchuan.png" />
              </div>
              <div class="index-title-name">病例照片附件</div>
              <div class="index-title-xia" style="width: 95px"></div>
            </div>
            <div
              style=" background-color: #ffffff;
                                                                                                                                                                                                                                                                                                                                                                            border-radius: 6px;
                                                                                                                                                                                                                                                                                                                                                                            padding: 18px 12px;">
              <el-form-item label="病例照片附件：" prop="attachments" class="inage-form-item" style="margin-bottom: 0;">
                <div class="upload-demo">
                  <el-upload :action="action" :on-remove="handleRemove" :before-remove="beforeRemove"
                    :on-success="handleAvatarSuccess" multiple :file-list="fileList" :before-upload="wenBeforeUpload"
                    :on-progress="onProgressTest" :data="uploadData">
                    <el-button size="small" type="primary" v-prevent="1500">点击上传</el-button>
                  </el-upload>
                </div>
              </el-form-item>
            </div>
            <!-- 图片 -->
            <div v-if="delShow" class="up-btn" @click="submitForm('form')" style="background-color: #7a75ff">
              发布病例
            </div>
          </div>
        </div>
        <!-- 患者基本系 -->
      </el-form>
    </div>
    <!-- 邀请码 -->
    <van-dialog v-model="dialogShow" title="邀请码" :showCancelButton="true" :before-close="beforeClose"
      @confirm="confirmCode">
      <van-form ref="vanform">
        <van-field v-model="form.invitation_code" placeholder="请输入邀请码" :rules="[{ required: true, message: '请输入邀请码' }]" />
      </van-form>
    </van-dialog>
    <!-- 悬浮 -->
    <div class="xuan">
      <transition name="el-zoom-in-bottom">
        <div class="xuan-ul" v-show="showPopover">
          <div class="xuan-li" :class="index == tabsIndex ? 'xuan-li-active' : ''" v-for="(item, index) in tabsList"
            :key="index" @click="tabsClick(index)">
            <div>{{ item.top }}</div>
            <div>{{ item.bottom }}</div>
          </div>
        </div>
      </transition>
      <transition name="fade">
        <div class="xuan-ru">
          <div class="xuan-ru-img" :class="{ anim: showPopover }" @click.stop="handleBtn()">
            <img src="@/assets/image/questionnaire/xuan.png" />
          </div>
        </div>
      </transition>
    </div>
    <!-- 悬浮 -->
  </div>
</template>
<script>
export default {
  name: "questionnaire",
  data() {
    //1
    var ppi_inputVerify = (rule, value, callback) => {
      if (this.form.medical_record_checkbox.includes("1") == true) {
        if (this.form.ppi_input == "") {
          callback(new Error("请输入内容"));
        } else {
          callback();
        }
      } else {
        callback();
      }
    };
    //2
    var ppi_usage_dosageVerify = (rule, value, callback) => {
      if (this.form.medical_record_checkbox.includes("1") == true) {
        if (this.form.ppi_usage_dosage == "") {
          callback(new Error("请输入内容"));
        } else {
          callback();
        }
      } else {
        callback();
      }
    };
    //3
    var bismuth_inputVerify = (rule, value, callback) => {
      if (this.form.medical_record_checkbox.includes("2") == true) {
        if (this.form.bismuth_input == "") {
          callback(new Error("请输入内容"));
        } else {
          callback();
        }
      } else {
        callback();
      }
    };
    //4
    var bismuth_usage_dosageVerify = (rule, value, callback) => {
      if (this.form.medical_record_checkbox.includes("2") == true) {
        if (this.form.bismuth_usage_dosage == "") {
          callback(new Error("请输入内容"));
        } else {
          callback();
        }
      } else {
        callback();
      }
    };
    //5
    var antibiotic1_inputVerify = (rule, value, callback) => {
      if (this.form.medical_record_checkbox.includes("3") == true) {
        if (this.form.antibiotic1_input == "") {
          callback(new Error("请输入内容"));
        } else {
          callback();
        }
      } else {
        callback();
      }
    };
    //6
    var antibiotic1_usage_dosageVerify = (rule, value, callback) => {
      if (this.form.medical_record_checkbox.includes("3") == true) {
        if (this.form.antibiotic1_usage_dosage == "") {
          callback(new Error("请输入内容"));
        } else {
          callback();
        }
      } else {
        callback();
      }
    };
    //7
    var antibiotic2_inputVerify = (rule, value, callback) => {
      if (this.form.medical_record_checkbox.includes("4") == true) {
        if (this.form.antibiotic2_input == "") {
          callback(new Error("请输入内容"));
        } else {
          callback();
        }
      } else {
        callback();
      }
    };
    //8
    var antibiotic2_usage_dosageVerify = (rule, value, callback) => {
      if (this.form.medical_record_checkbox.includes("4") == true) {
        if (this.form.antibiotic2_usage_dosage == "") {
          callback(new Error("请输入内容"));
        } else {
          callback();
        }
      } else {
        callback();
      }
    };
    return {
      value1: 2,
      action: `https://up-z0.qiniup.com`,
      fileList: [],
      uploadData: { key: "", token: "" },
      aaa: "",
      //年月日
      year: "",
      month: "",
      day: "",
      dialogShow: false,
      //----------------------- 
      Annexshow: true,
      tabsIndex: 0,
      allOption: {},
      delShow: true,
      form: {
        id: "",
        act_id: "1",
        invitation_code: "",
        //基本信息  
        name: "", //姓名 
        age: "", //年级
        sex: "", //性别  
        hp_check_date: '',
        hp_check_method: '',
        hp_check_method_input: '',
        hp_check_result: '',
        disease_diagnosis: '',
        disease_diagnosis_input: '',
        gastroscopy_diagnosis: '',
        gastroscopy_diagnosis_input: '',
        case_diagnosis: '',
        case_diagnosis_input: '',
        hp_treatment_history: '',
        hp_treatment_history_input: '',
        //治疗方案 
        medical_record_checkbox: [], //用药记录复选框 
        ppi_input: '',//ppi输入记录
        ppi_usage_dosage: '',//ppi用法用量
        bismuth_input: '',//铋剂输入
        bismuth_usage_dosage: '',//铋剂用法用量
        antibiotic1_input: '',//抗生素1输入 
        antibiotic1_usage_dosage: '',//抗生素1用法用量
        antibiotic2_input: '',//抗生素2输入
        antibiotic2_usage_dosage: '',//抗生素2用法用量
        biling_granules_input: '',//荜铃胃痛颗粒输入
        biling_granules_usage_dosage: '',//荜铃胃痛颗粒用法用量
        start_taking_medicine: '',//开始服药时间
        stop_taking_medicine: '',//停止服药时间 
        adjust_medication: '',//是否调整用药方案 
        adjust_medication_reason: '',//调整药物原因
        //不良反应检测
        adversereaction: '',//是否产生不良反应 1是 2否 
        adversereaction_group: [],//反应表现复选id 
        adversereaction_group_input: '',//反应表现其他输入
        adversereaction_date: '',//日期
        adversereaction_immediately: '',//是否用药后立即发生 1是 2否
        adversereaction_time_input: '',//用药几小时 
        adversereaction_degree: '',//不良反应的严重程度单选id  
        adversereaction_related: '', //药物相关 单选id
        //治疗前症状评分
        pre_degree_ep_pain: 0,//治疗前-程度-上腹痛
        pre_degree_after_meal_satiety: 0,//治疗前-程度-餐后饱张
        pre_degree_premature_satiety: 0,//治疗前-程度-早饱
        pre_degree_burn_upper_abdomen: 0,//治疗前-程度-上腹烧灼
        pre_frequency_ep_pain: 0,//治疗前-频率-上腹痛 
        pre_frequency_after_meal_satiety: 0,//治疗前-频率-餐后饱张
        pre_frequency_premature_satiety: 0,//治疗前-频率-早饱
        pre_frequency_burn_upper_abdomen: 0,//治疗前-频率-上腹烧灼
        pre_other_acid_regurgitation: '',//其他-泛酸
        pre_other_heartburn: '',//其他-烧心
        pre_other_shit: '',//其他-大便
        pre_other_fatigued: '',//其他-疲乏
        pre_other_poor_sleep: '',//其他-睡眠差
        pre_other_irritability: '',//其他-烦躁易怒
        pre_other_anxiety: '',//其他-焦虑
        pre_other_depressed: '',//其他-抑郁 
        //疗效评价
        after_hp_check_date: '',//治疗后检查时间
        after_hp_check_method: '',//治疗后检查方法
        after_hp_check_method_input: '',//治疗后检查方法输入
        after_hp_check_result: '',//治疗后检查结果
        after_post_degree_ep_pain: 0,//治疗后-程度-上腹痛
        after_post_degree_after_meal_satiety: 0,//治疗后-程度-餐后饱张
        after_post_degree_premature_satiety: 0,//治疗后-程度-早饱
        after_post_degree_burn_upper_abdomen: 0,//治疗后-程度-上腹烧灼
        after_post_frequency_ep_pain: 0,//治疗后-频率-上腹痛
        after_post_frequency_after_meal_satiety: 0,//治疗后-频率-餐后饱胀
        after_post_frequency_premature_satiety: 0,//治疗后-频率-早饱 
        after_post_frequency_burn_upper_abdomen: 0,//治疗后-频率-上腹烧灼
        after_other_acid_regurgitation: '',//治疗后-其他-泛酸
        after_other_heartburn: '',//治疗后-其他-烧心
        after_other_shit: '',//治疗后-其他-大便
        after_other_fatigued: '',//治疗后-其他-疲乏
        after_other_poor_sleep: '',//治疗后-其他-睡眠差
        after_other_irritability: '',//治疗后-其他-烦躁易怒
        after_other_anxiety: '',//治疗后-其他-焦虑
        after_other_depressed: '',//治疗后-其他-抑郁 
        //资料  
        attachments: "",
        //总分
        pre_degree_amount: '', //治疗前-程度-总分
        pre_frequency_amount: '',//治疗前-频率-总分
        after_post_degree_amount: '',//治疗后-症状-总分 
        after_post_frequency_amount: '',//治疗后-频率-总分
      },
      rules: {
        //基本信息
        name: [{ required: true, message: "请输入姓名", trigger: "blur" }],
        age: [{ required: true, message: "请输入年龄", trigger: "blur" }],
        sex: [{ required: true, message: "请选择性别", trigger: "change" }],
        hp_check_date: [{ required: true, message: "请选择日期", trigger: "change" },],
        hp_check_method: [{ required: true, message: "请选择检查方法", trigger: "change" },],
        hp_check_method_input: [{ required: true, message: "请输入内容", trigger: "blur" }],
        hp_check_result: [{ required: true, message: "请选择检查方法", trigger: "change" },],
        disease_diagnosis: [{ required: true, message: "请选择疾病诊断", trigger: "change" },],
        disease_diagnosis_input: [{ required: true, message: "请输入内容", trigger: "blur" }],
        gastroscopy_diagnosis: [{ required: true, message: "请选择胃镜诊断", trigger: "change" },],
        gastroscopy_diagnosis_input: [{ required: true, message: "请输入内容", trigger: "blur" }],
        case_diagnosis: [{ required: true, message: "请选择病理诊断", trigger: "change" },],
        case_diagnosis_input: [{ required: true, message: "请输入内容", trigger: "blur" }],
        hp_treatment_history: [{ required: true, message: "请选择既往Hp感染治疗史", trigger: "change" },],
        hp_treatment_history_input: [{ required: true, message: "请输入内容", trigger: "blur" }],
        //治疗方案
        biling_granules_input: [{ required: true, message: "请输入内容", trigger: "blur" }],
        biling_granules_usage_dosage: [{ required: true, message: "请输入内容", trigger: "blur" }],
        start_taking_medicine: [{ required: true, message: "请选择开始服药时间", trigger: "change" },],
        stop_taking_medicine: [{ required: true, message: "请选择停止服药时间", trigger: "change" },],
        adjust_medication: [{ required: true, message: "请选择治疗期间是否调整用药方案", trigger: "change" },],
        adjust_medication_reason: [{ required: true, message: "请输入内容", trigger: "blur" }],
        //不良反应检测 
        adversereaction: [{ required: true, message: "请选择患者是否产生不良反应", trigger: "change", },],
        adversereaction_group: [{ required: true, message: "请选择不良反应表现", trigger: "change", },],
        adversereaction_group_input: [{ required: true, message: "请输入其他", trigger: "blur", },],
        adversereaction_date: [{ required: true, message: "请选择不发生日期", trigger: "change", },],
        adversereaction_immediately: [{ required: true, message: "请选择是否用药后立即发生", trigger: "change", },],
        adversereaction_time_input: [{ required: true, message: "请输入反应发生时间，用药后几小时", trigger: "blur", },],
        adversereaction_degree: [{ required: true, message: "请选择不良反应的严重程度", trigger: "change", },],
        adversereaction_related: [{ required: true, message: "请选择不是否与观察药物相关", trigger: "change", },],
        //治疗前症状评分
        pre_other_acid_regurgitation: [{ required: true, message: "请选择反酸", trigger: "change", },],
        pre_other_heartburn: [{ required: true, message: "请选择烧心", trigger: "change", },],
        pre_other_shit: [{ required: true, message: "请选择大便", trigger: "change", },],
        pre_other_fatigued: [{ required: true, message: "请选择疲乏", trigger: "change", },],
        pre_other_poor_sleep: [{ required: true, message: "请选择睡眠", trigger: "change", },],
        pre_other_irritability: [{ required: true, message: "请选择烦躁易怒", trigger: "change", },],
        pre_other_anxiety: [{ required: true, message: "请选择焦虑", trigger: "change", },],
        pre_other_depressed: [{ required: true, message: "请选择抑郁", trigger: "change", },],
        //疗效评价 
        after_hp_check_date: [{ required: true, message: "请选择检查日期", trigger: "change", },],
        after_hp_check_method: [{ required: true, message: "请选择检方法", trigger: "change", },],
        after_hp_check_method_input: [{ required: true, message: "输入内容", trigger: "blur", },],
        after_hp_check_result: [{ required: true, message: "请选择检查结果", trigger: "change", },],

        after_other_acid_regurgitation: [{ required: true, message: "请选择反酸", trigger: "change", },],
        after_other_heartburn: [{ required: true, message: "请选择烧心", trigger: "change", },],
        after_other_shit: [{ required: true, message: "请选择大便", trigger: "change", },],
        after_other_fatigued: [{ required: true, message: "请选择疲乏", trigger: "change", },],
        after_other_poor_sleep: [{ required: true, message: "请选择睡眠", trigger: "change", },],
        after_other_irritability: [{ required: true, message: "请选择烦躁易怒", trigger: "change", },],
        after_other_anxiety: [{ required: true, message: "请选择焦虑", trigger: "change", },],
        after_other_depressed: [{ required: true, message: "请选择抑郁", trigger: "change", },],
        //病例资料
        attachments: [{ required: true, message: "请上传病例照片附件", trigger: "change" },],
        //
        ppi_input: [{ validator: ppi_inputVerify, trigger: ["blur", "change"], },],
        ppi_usage_dosage: [{ validator: ppi_usage_dosageVerify, trigger: ["blur", "change"], },],
        bismuth_input: [{ validator: bismuth_inputVerify, trigger: ["blur", "change"], },],
        bismuth_usage_dosage: [{ validator: bismuth_usage_dosageVerify, trigger: ["blur", "change"], },],
        antibiotic1_input: [{ validator: antibiotic1_inputVerify, trigger: ["blur", "change"], },],
        antibiotic1_usage_dosage: [{ validator: antibiotic1_usage_dosageVerify, trigger: ["blur", "change"], },],
        antibiotic2_input: [{ validator: antibiotic2_inputVerify, trigger: ["blur", "change"], },],
        antibiotic2_usage_dosage: [{ validator: antibiotic2_usage_dosageVerify, trigger: ["blur", "change"], },],
        //@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
        pre_degree_ep_pain: [
          { required: true, message: '请选择症状', trigger: 'change' },
          {
            validator: (rule, value, callback) => {
              const min = 1;
              const max = 5;
              if (value < min || value > max) {
                callback(new Error(`症状必须在无症状和极严重之间`));
              } else {
                callback();
              }
            },
            trigger: 'change',
          },],
        pre_degree_after_meal_satiety: [
          { required: true, message: '请选择症状', trigger: 'change' },
          {
            validator: (rule, value, callback) => {
              const min = 1;
              const max = 5;
              if (value < min || value > max) {
                callback(new Error(`症状必须在无症状和极严重之间`));
              } else {
                callback();
              }
            },
            trigger: 'change',
          },],
        pre_degree_premature_satiety: [
          { required: true, message: '请选择症状', trigger: 'change' },
          {
            validator: (rule, value, callback) => {
              const min = 1;
              const max = 5;
              if (value < min || value > max) {
                callback(new Error(`症状必须在无症状和极严重之间`));
              } else {
                callback();
              }
            },
            trigger: 'change',
          },],
        pre_degree_burn_upper_abdomen: [
          { required: true, message: '请选择症状', trigger: 'change' },
          {
            validator: (rule, value, callback) => {
              const min = 1;
              const max = 5;
              if (value < min || value > max) {
                callback(new Error(`症状必须在无症状和极严重之间`));
              } else {
                callback();
              }
            },
            trigger: 'change',
          },],
        pre_frequency_ep_pain: [
          { required: true, message: '请选择症状', trigger: 'change' },
          {
            validator: (rule, value, callback) => {
              const min = 1;
              const max = 5;
              if (value < min || value > max) {
                callback(new Error(`症状必须在无症状和极严重之间`));
              } else {
                callback();
              }
            },
            trigger: 'change',
          },],
        pre_frequency_after_meal_satiety: [
          { required: true, message: '请选择症状', trigger: 'change' },
          {
            validator: (rule, value, callback) => {
              const min = 1;
              const max = 5;
              if (value < min || value > max) {
                callback(new Error(`症状必须在无症状和极严重之间`));
              } else {
                callback();
              }
            },
            trigger: 'change',
          },],
        pre_frequency_premature_satiety: [
          { required: true, message: '请选择症状', trigger: 'change' },
          {
            validator: (rule, value, callback) => {
              const min = 1;
              const max = 5;
              if (value < min || value > max) {
                callback(new Error(`症状必须在无症状和极严重之间`));
              } else {
                callback();
              }
            },
            trigger: 'change',
          },],
        pre_frequency_burn_upper_abdomen: [
          { required: true, message: '请选择症状', trigger: 'change' },
          {
            validator: (rule, value, callback) => {
              const min = 1;
              const max = 5;
              if (value < min || value > max) {
                callback(new Error(`症状必须在无症状和极严重之间`));
              } else {
                callback();
              }
            },
            trigger: 'change',
          },],
        //@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
        after_post_degree_ep_pain: [
          { required: true, message: '请选择症状', trigger: 'change' },
          {
            validator: (rule, value, callback) => {
              const min = 1;
              const max = 5;
              if (value < min || value > max) {
                callback(new Error(`症状必须在无症状和极严重之间`));
              } else {
                callback();
              }
            },
            trigger: 'change',
          },],
        after_post_degree_after_meal_satiety: [
          { required: true, message: '请选择症状', trigger: 'change' },
          {
            validator: (rule, value, callback) => {
              const min = 1;
              const max = 5;
              if (value < min || value > max) {
                callback(new Error(`症状必须在无症状和极严重之间`));
              } else {
                callback();
              }
            },
            trigger: 'change',
          },],
        after_post_degree_premature_satiety: [
          { required: true, message: '请选择症状', trigger: 'change' },
          {
            validator: (rule, value, callback) => {
              const min = 1;
              const max = 5;
              if (value < min || value > max) {
                callback(new Error(`症状必须在无症状和极严重之间`));
              } else {
                callback();
              }
            },
            trigger: 'change',
          },],
        after_post_degree_burn_upper_abdomen: [
          { required: true, message: '请选择症状', trigger: 'change' },
          {
            validator: (rule, value, callback) => {
              const min = 1;
              const max = 5;
              if (value < min || value > max) {
                callback(new Error(`症状必须在无症状和极严重之间`));
              } else {
                callback();
              }
            },
            trigger: 'change',
          },],
        after_post_frequency_ep_pain: [
          { required: true, message: '请选择症状', trigger: 'change' },
          {
            validator: (rule, value, callback) => {
              const min = 1;
              const max = 5;
              if (value < min || value > max) {
                callback(new Error(`症状必须在无症状和极严重之间`));
              } else {
                callback();
              }
            },
            trigger: 'change',
          },],
        after_post_frequency_after_meal_satiety: [
          { required: true, message: '请选择症状', trigger: 'change' },
          {
            validator: (rule, value, callback) => {
              const min = 1;
              const max = 5;
              if (value < min || value > max) {
                callback(new Error(`症状必须在无症状和极严重之间`));
              } else {
                callback();
              }
            },
            trigger: 'change',
          },],
        after_post_frequency_premature_satiety: [
          { required: true, message: '请选择症状', trigger: 'change' },
          {
            validator: (rule, value, callback) => {
              const min = 1;
              const max = 5;
              if (value < min || value > max) {
                callback(new Error(`症状必须在无症状和极严重之间`));
              } else {
                callback();
              }
            },
            trigger: 'change',
          },],
        after_post_frequency_burn_upper_abdomen: [
          { required: true, message: '请选择症状', trigger: 'change' },
          {
            validator: (rule, value, callback) => {
              const min = 1;
              const max = 5;
              if (value < min || value > max) {
                callback(new Error(`症状必须在无症状和极严重之间`));
              } else {
                callback();
              }
            },
            trigger: 'change',
          },],
        //@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
        ppi_input: [{ required: true, message: "请输入内容", trigger: "blur" }],
        ppi_usage_dosage: [{ required: true, message: "请输入内容", trigger: "blur" }],
        bismuth_input: [{ required: true, message: "请输入内容", trigger: "blur" }],
        bismuth_usage_dosage: [{ required: true, message: "请输入内容", trigger: "blur" }],
        antibiotic1_input: [{ required: true, message: "请输入内容", trigger: "blur" }],
        antibiotic1_usage_dosage: [{ required: true, message: "请输入内容", trigger: "blur" }],
        antibiotic2_input: [{ required: true, message: "请输入内容", trigger: "blur" }],
        antibiotic2_usage_dosage: [{ required: true, message: "请输入内容", trigger: "blur" }],
      },
      //ae  
      AElist: [
        {
          c: "轻度",
          t: "受试者可忍受，不影响治疗，不需要特别处理，对受试者康复无影响。",
        },
        {
          c: "中度",
          t: "受试者难以忍受，需要撤药中止试验或做特殊处理，对受试者康复有直接影响。",
        },
        {
          c: "重度",
          t: "危及受试者生命，致死或致残，需立即撤药或做紧急处理。凡严重不良事件请填写严重不良事件报告表，并按规定程序报告。",
        },
      ],
      //悬浮
      showPopover: false,
      tabsList: [
        { top: "基本", bottom: "信息" },
        { top: "疗前", bottom: "评分" },
        { top: "治疗", bottom: "方案" },
        { top: "疗效", bottom: "评价" },
        { top: "不良", bottom: "反应" },
        { top: "病例", bottom: "照片" },
      ],
    };
  },
  mounted() {
    document.documentElement.scrollTop = 0;
    if (this.$route.query.del != undefined) {
      this.delShow = false;
    }
    if (this.$route.query.id == undefined) {
      this.dialogShow = true; //弹窗;     
    } else {
      this.getformdetail(this.$route.query.id);
    }
    //监听滚动距离
    this.$nextTick(() => {
      window.addEventListener("scroll", this.handleScroll, true);
    });
  },
  computed: {
    style1() {
      const length = 5 - 0,
        progress = this.form.pre_degree_ep_pain - 0,
        left = (progress / length) * 100;
      return {
        paddingLeft: `${left}%`,
      };
    },
    style2() {
      const length = 5 - 0,
        progress = this.form.pre_degree_after_meal_satiety - 0,
        left = (progress / length) * 100;
      return {
        paddingLeft: `${left}%`,
      };
    },
    style3() {
      const length = 5 - 0,
        progress = this.form.pre_degree_premature_satiety - 0,
        left = (progress / length) * 100;
      return {
        paddingLeft: `${left}%`,
      };
    },
    style4() {
      const length = 5 - 0,
        progress = this.form.pre_degree_burn_upper_abdomen - 0,
        left = (progress / length) * 100;
      return {
        paddingLeft: `${left}%`,
      };
    },
    style5() {
      const length = 5 - 0,
        progress = this.form.pre_frequency_ep_pain - 0,
        left = (progress / length) * 100;
      return {
        paddingLeft: `${left}%`,
      };
    },
    style6() {
      const length = 5 - 0,
        progress = this.form.pre_frequency_after_meal_satiety - 0,
        left = (progress / length) * 100;
      return {
        paddingLeft: `${left}%`,
      };
    },
    style7() {
      const length = 5 - 0,
        progress = this.form.pre_frequency_premature_satiety - 0,
        left = (progress / length) * 100;
      return {
        paddingLeft: `${left}%`,
      };
    },
    style8() {
      const length = 5 - 0,
        progress = this.form.pre_frequency_burn_upper_abdomen - 0,
        left = (progress / length) * 100;
      return {
        paddingLeft: `${left}%`,
      };
    },
    style9() {
      const length = 5 - 0,
        progress = this.form.after_post_degree_ep_pain - 0,
        left = (progress / length) * 100;
      return {
        paddingLeft: `${left}%`,
      };
    },
    style10() {
      const length = 5 - 0,
        progress = this.form.after_post_degree_after_meal_satiety - 0,
        left = (progress / length) * 100;
      return {
        paddingLeft: `${left}%`,
      };
    },
    style11() {
      const length = 5 - 0,
        progress = this.form.after_post_degree_premature_satiety - 0,
        left = (progress / length) * 100;
      return {
        paddingLeft: `${left}%`,
      };
    },
    style12() {
      const length = 5 - 0,
        progress = this.form.after_post_degree_burn_upper_abdomen - 0,
        left = (progress / length) * 100;
      return {
        paddingLeft: `${left}%`,
      };
    },
    style13() {
      const length = 5 - 0,
        progress = this.form.after_post_frequency_ep_pain - 0,
        left = (progress / length) * 100;
      return {
        paddingLeft: `${left}%`,
      };
    },
    style14() {
      const length = 5 - 0,
        progress = this.form.after_post_frequency_after_meal_satiety - 0,
        left = (progress / length) * 100;
      return {
        paddingLeft: `${left}%`,
      };
    },
    style15() {
      const length = 5 - 0,
        progress = this.form.after_post_frequency_premature_satiety - 0,
        left = (progress / length) * 100;
      return {
        paddingLeft: `${left}%`,
      };
    },
    style16() {
      const length = 5 - 0,
        progress = this.form.after_post_frequency_burn_upper_abdomen - 0,
        left = (progress / length) * 100;
      return {
        paddingLeft: `${left}%`,
      };
    },
    //治疗前-程度-总分
    pre_degree() {
      this.form.pre_degree_amount = this.form.pre_degree_ep_pain +
        this.form.pre_degree_after_meal_satiety + this.form.pre_degree_premature_satiety +
        this.form.pre_degree_burn_upper_abdomen
      return this.form.pre_degree_amount
    },
    //治疗前-频率-总分 
    pre_frequency() {
      this.form.pre_frequency_amount = this.form.pre_frequency_ep_pain +
        this.form.pre_frequency_after_meal_satiety + this.form.pre_frequency_premature_satiety +
        this.form.pre_frequency_burn_upper_abdomen
      return this.form.pre_frequency_amount
    },
    //治疗后-症状-总分   
    degree_amount() {
      this.form.after_post_degree_amount = this.form.after_post_degree_ep_pain +
        this.form.after_post_degree_after_meal_satiety + this.form.after_post_degree_premature_satiety +
        this.form.after_post_degree_burn_upper_abdomen
      return this.form.after_post_degree_amount
    },
    //治疗后-症状-总分    
    frequency_amount() {
      this.form.after_post_frequency_amount = this.form.after_post_frequency_ep_pain +
        this.form.after_post_frequency_after_meal_satiety + this.form.after_post_frequency_premature_satiety +
        this.form.after_post_frequency_burn_upper_abdomen
      return this.form.after_post_frequency_amount
    },
  },
  methods: {
    //页面滚动 
    handleScroll(e) {
      let inref = this.$refs.inref.getBoundingClientRect().top;               //基本信息
      let zhongyiref = this.$refs.zhongyiref.getBoundingClientRect().top;     //症状评分 
      let treatmentref = this.$refs.treatmentref.getBoundingClientRect().top; //治疗方案 
      let evaluateref = this.$refs.evaluateref.getBoundingClientRect().top;   //疗效评估 
      let adversereactionref = this.$refs.adversereactionref.getBoundingClientRect().top; //不良反应 
      // let uploadref = this.$refs.uploadref.getBoundingClientRect().top;        //上传
      if (inref <= 0) {
        this.tabsIndex = 0;
      }
      if (zhongyiref <= 70) {
        this.tabsIndex = 1;
      }
      if (treatmentref <= 70) {
        this.tabsIndex = 2;
      }
      if (evaluateref <= 70) {
        this.tabsIndex = 3;
      }
      if (adversereactionref <= 420) {
        this.tabsIndex = 4;
      }
      //上传文件
      let docEl = document.documentElement
      let clientHeight = document.documentElement.clientHeight || document.body.clientHeight
      window.addEventListener('scroll', () => {
        let docELHeight = docEl.scrollHeight
        let scrollTop = docEl.scrollTop
        if (scrollTop == docELHeight - clientHeight) {
          this.tabsIndex = 5;
        }
      })
    },
    handleBtn() {
      this.showPopover = !this.showPopover;
    },
    tabsClick(index) {
      this.tabsIndex = index;
      this.getscrollTop(index);
    },
    //点击滚动
    getscrollTop(index) {
      document.documentElement.scrollTop = 0;               //基本信息
      let zhongyiref = this.$refs.zhongyiref.getBoundingClientRect().top;     //症状评分 
      let treatmentref = this.$refs.treatmentref.getBoundingClientRect().top; //治疗方案 
      let evaluateref = this.$refs.evaluateref.getBoundingClientRect().top;   //疗效评估 
      let adversereactionref = this.$refs.adversereactionref.getBoundingClientRect().top; //不良反应
      let uploadref = this.$refs.uploadref.getBoundingClientRect().top;
      if (index == 0) {
        document.documentElement.scrollTop = 0;
      }
      if (index == 1) {
        document.documentElement.scrollTop = zhongyiref - 60;
      }
      if (index == 2) {
        document.documentElement.scrollTop = treatmentref - 60;
      }
      if (index == 3) {
        document.documentElement.scrollTop = evaluateref - 60;
      }
      if (index == 4) {
        document.documentElement.scrollTop = adversereactionref - 380;
      }
      if (index == 5) {
        document.documentElement.scrollTop = uploadref;
      }
    },
    //邀请码确认
    confirmCode() { },
    //邀请码关闭前
    async beforeClose(action, done) {
      // new Promise(async (resolve) => {
      if (action == "confirm") {
        try {
          const { data: data } = await this.$http.post(
            "medical/checkInvitationCode",
            { code: this.form.invitation_code }
          );
          if (data.code == 200) {
            return done(true);
          }
        } catch (error) {
          return done(false);
        }
      } else if (action == "cancel") {
        setTimeout(() => {
          done(true);
          this.$router.push("/homeTopic");
        }, 500);
      }
      // });
    },
    //检查邀请码
    async checkCode() {
      const { data: data } = await this.$http.post(
        "medical/checkInvitationCode",
        { code: this.form.invitation_code }
      );
    },
    //提交
    submitForm(formName) {
      this.$refs[formName].validate((valid, object) => {
        if (valid) {
          this.$dialog.confirm({
            title: '提示',
            message: '请核对填写的信息，确定要发布该病例吗？',
          })
            .then(() => {
              this.getsubmitApi();
            })
            .catch(() => {
            });
        } else {
          console.log("error submit!!");
          // this.locateToErr();
          this.scrollToView(this, object);
          return false;
        }
      });
    },
    //提交接口
    async getsubmitApi() {
      const { data: data } = await this.$http.post("auth/medical", this.form);
      if (data.code == 200) {
        this.$toast(data.message);
        this.$router.push("/my");
      }
    },
    //获取详情
    async getformdetail(id) {
      const { data: data } = await this.$http.post("medical/detail", { id });
      this.form = data.data;
      this.form.id = id;
      this.aaa = data.data.attachments;
      this.form.attachments = data.data.attachments;
      this.fileList = data.data.attachments_data;
    },
    // 滚动条定位到第一个校验失败的div的位置
    locateToErr() {
      setTimeout(() => {
        let errorDiv = document.getElementsByClassName("is-error");
        errorDiv[0].scrollIntoView();
      }, 0);
    },
    //既往检查
    check_true_laboratoryClick(val) {
      if (val == false) {
        this.form.check_true_laboratory = "";
      }
    },
    check_true_imageClick(val) {
      if (val == false) {
        this.form.check_true_image = "";
      }
    },
    check_true_pathologyClick(val) {
      if (val == false) {
        this.form.check_true_pathology = "";
      }
    },
    check_true_otherClick(val) {
      if (val == false) {
        this.form.check_true_other = "";
      }
    },
    //消化疾病相关检查
    digestive_diseases_laboratoryClick(val) {
      if (val == false) {
        this.form.digestive_diseases_laboratory = "";
      }
    },
    digestive_diseases_imageClick(val) {
      if (val == false) {
        this.form.digestive_diseases_image = "";
      }
    },
    digestive_diseases_pathologyClick(val) {
      if (val == false) {
        this.form.digestive_diseases_pathology = "";
      }
    },
    digestive_diseases_otherClick(val) {
      if (val == false) {
        this.form.digestive_diseases_other = "";
      }
    },
    //合并正
    comorbidities_anxietyClick(val) {
      if (val == false) {
        this.form.comorbidities_anxiety = "";
      }
    },
    comorbidities_depressionClick(val) {
      if (val == false) {
        this.form.comorbidities_depression = "";
      }
    },
    comorbidities_otherClick(val) {
      if (val == false) {
        this.form.comorbidities_other = "";
      }
    },
    //上传
    //上传前
    async wenBeforeUpload(file) {
      //获取七牛云的token
      const { data: data } = await this.$http.get("system/qiniuToken");
      this.uploadData.token = data.data.token;
      this.uploadData.key = `medical-${this.year}-${this.month}-${this.day}-${file.uid}/${file.name}`;
      return true;
    },
    //文件上传时
    onProgressTest(event, file, fileList) {
      let index = 0;
      // 只有fileList 跟file 里面的uid是唯一的互相对应的
      fileList.forEach((v, i) => {
        if (v.uid === file.uid) {
          index = i;
        }
      });
      // 获取动态的进度
      let percent = parseInt(event.percent);
      this.$nextTick(() => {
        setTimeout(() => {
          if (percent >= 99) {
            // 当动态的进度超过99就获取dom 让值一直显示99直到上传成功
            let dom = document.getElementsByClassName("el-upload-list__item")[
              index
            ];
            let progressText = dom.getElementsByClassName("el-progress__text");
            if (progressText && progressText.length) {
              progressText[0].style.opacity = 0;
              progressText[0].innerText = "99%";
              progressText[0].style.opacity = 1;
            }
          }
        });
      });
    },
    //文件上传成功
    handleAvatarSuccess(res, file) {
      if (res.key != "" || res.key != undefined || res.key != null) {
        this.aaa += `https://resources.hp.medcase.com.cn/${res.key},`;
        this.form.attachments = this.aaa;
        this.$toast.success("文件上传成功");
      } else {
        this.$toast.fail("上传失败");
      }
    },
    //移除文件 提示
    beforeRemove(file, fileList) {
      return this.$toast.success("移除成功");
    },
    //移除后
    handleRemove(file, fileList) {
      this.aaa = "";
      let qiniuUrl = "";
      let apiUrl = "";
      fileList.map((item) => {
        if (item.response != undefined) {
          qiniuUrl +=
            `https://resources.hp.medcase.com.cn/${item.response.key}` + ",";
        }
        if (item.url) {
          apiUrl += item.url + ",";
        }
      });
      this.aaa = apiUrl + qiniuUrl;
      this.form.attachments = this.aaa;
    },
    //用药记录
    check_true_ppi(val) {
      if (val == false) {
        this.form.ppi_input = "";
        this.form.ppi_usage_dosage = "";
      }
    },
    check_true_miji(val) {
      if (val == false) {
        this.form.bismuth_input = "";
        this.form.bismuth_usage_dosage = "";
      }
    },
    check_true_kang1(val) {
      if (val == false) {
        this.form.antibiotic1_input = "";
        this.form.antibiotic1_usage_dosage = "";
      }
    },
    check_true_kang2(val) {
      if (val == false) {
        this.form.antibiotic2_input = "";
        this.form.antibiotic2_usage_dosage = "";
      }
    }
  },
};
</script>
<style lang="less" scoped>
.index {
  position: relative;
  color: #606266 !important;

  .index-head {
    position: relative;
    top: 0;
    left: -16px;
    width: 109%;
    height: 96px;
  }

  .index-head-name {
    position: absolute;
    top: 0;
    padding: 20px 40px;
    // left: 80px;
    color: #fff;
    font-size: 18px;
    font-weight: 600;
    text-align: center;
  }

  // 头
  .index-title {
    position: relative;
    display: flex;
    align-items: center;
    margin-bottom: 17px;

    .index-title-img {
      width: 15px;
      height: 15px;
      margin-right: 3px;
    }

    .index-title-name {
      position: relative;
      font-size: 14px;
      color: #000000;
      font-weight: 600;
      z-index: 1;
    }

    .index-title-xia {
      position: absolute;
      bottom: -1px;
      left: 20px;
      width: 88px;
      height: 7px;
      background-color: #a8efcc;
    }
  }

  // 基本信息
  .biaodan {
    background-color: #f6f8ff;

    //输入框
    .inform-box-input {
      position: relative;

      .form-input-xia {
        position: absolute;
        top: -20px;
        left: 0;
        width: 182px;
      }

      /deep/ .el-input__inner {
        border: none !important;
        border-radius: 0 !important;
        border-bottom: 1px solid #dcdfe6 !important;
        // padding: 0 !important;
        color: #606266;
        font-size: 13px !important;
        height: 20px !important;
        line-height: 20px;
        // background-color: pink;
        padding-left: 15px;

      }

      /deep/ .el-input {
        height: 20px !important;
        line-height: 20px !important;
      }
    }

    .shurukuang {
      position: relative;

      .form-input-xia {
        position: absolute;
        top: -20px;
        left: 0;
        width: 182px;
      }

      /deep/ .el-input__inner {
        border: none !important;
        border-radius: 0 !important;
        border-bottom: 1px solid #dcdfe6 !important;
        // padding: 0 !important;
        color: #606266;
        font-size: 13px !important;
        height: 20px !important;
        line-height: 20px;
        // background-color: pink;
        padding-left: 15px;

      }

      /deep/ .el-input {
        height: 20px !important;
        line-height: 20px !important;
      }
    }

    //label

    .inform-box-title {
      color: #606266 !important;
      font-size: 14px;
    }

    .inform {
      position: relative;
      left: -16px;
      // top: -20px;
      width: 109%;
      border-radius: 15px 15px 0px 0px;
      padding: 20px 15px;

      background-color: #f6f8ff;

      //内容
      .inform-box {
        position: relative;
        width: 100%;
        background-color: #ffffff;
        border-radius: 6px;
        padding: 18px 12px;

        .inform-box-item-flex {
          position: relative;
          width: 100%;
          display: flex;
          align-items: center;

          /deep/.el-input {
            width: 124px !important;
          }

          .inform-box-item-flex-input-rig {
            margin-left: 8px;
          }
        }

        /deep/ .el-form-item__label {
          line-height: 0 !important;
        }

        /deep/ .el-radio {
          margin-right: 45px;
          margin-top: 24px;
        }

        // /deep/ .el-checkbox {
        //   margin-right: 27px !important;
        //   &:nth-child(7) {
        //     margin-right: 0 !important;
        //   }
        // }
        .inform-box-fen {
          margin-top: 15px;
          display: flex;
          align-items: center;

          .inform-box-fen-left {
            display: flex;
            align-items: center;
            color: #606266;
            margin-right: 5px;
          }

          .inform-box-fen-right {
            display: flex;
            align-items: center;
            color: #606266;
          }
        }

        .inform-box-jiancha {
          padding: 12px;
          background-color: #f5f7f9;
          border-radius: 8px;
          margin-top: 15px;

          .inform-box-jiancha-title {
            color: #2b4fdf;
            margin-bottom: 10px;
          }

          /deep/.el-input__inner {
            background-color: #f5f7f9 !important;


          }
        }

        .inform-box-form-item {
          /deep/.el-form-item__label {
            margin: 10px 0 15px 0;
          }
        }
      }

      .inform-box-lable {
        margin-bottom: 30px;

        /deep/ .el-form-item__label {
          line-height: 40px !important;
        }
      }
    }

    .inform-box-item-flex-name {
      width: 90px;
      color: #606266 !important;

      &::before {
        content: "*";
        position: relative;
        margin-right: 4.4px;
        color: #f56c6c;
      }
    }

    .inform-box-item-flex-shuang {
      display: flex;
      align-items: center;
      justify-content: space-between;
      position: relative;
      margin-left: 10px;

      .inform-box-item-flex-shuang-box {
        display: flex;
        align-items: center;
        position: relative;
        color: #606266 !important;
      }

      /deep/ .el-input__inner {
        // width: 60px !important;
        border: none !important;
        border-radius: 0 !important;
        border-bottom: 1px solid #dcdfe6 !important;
        padding: 0 !important;
        height: 19px !important;
        color: #606266;
        font-size: 13px !important;
      }
    }

    .ping-box {
      display: flex;
      align-items: center;

      .ping-box-img {
        width: 150px;
        height: 24px;
        margin-right: 3px;
      }
    }

    .ping-xiang {
      line-height: 1.5;
    }

    .down-btn {
      background-color: #0ba29a;
      color: #fff;
      border-radius: 18px;
      padding: 10px 0;
      width: 100%;
      text-align: center;
      margin-top: 30px;
    }

    .up-btn {
      background-color: #3887fe;
      color: #fff;
      border-radius: 18px;
      padding: 10px 0;
      width: 100%;
      text-align: center;
      // margin-top: 14px;
      margin: 20px 0;
    }

    .inform-box-buliang {
      margin-top: 15px;
      padding: 18px 10px;
      background-color: #f5f7f9;
      border-radius: 8px;

      /deep/ .el-input__inner {
        // background-color: #f5f7f9 !important; 
        background-color: #fff !important;
      }

      /deep/ .el-radio {
        margin-right: 25px !important;
      }
    }

    .inform-b-bgc {
      width: 317px;
      height: 237px;
      margin-top: 19px;
      margin-bottom: 10px;
    }
  }

  .zhusu {
    /deep/ .el-checkbox {
      margin-right: 27px !important;

      &:nth-child(7) {
        margin-right: 0 !important;
      }
    }
  }

  .buliang {
    /deep/ .el-checkbox {
      margin-right: 20px !important;

      &:nth-child(4) {
        margin-right: 0 !important;
      }
    }
  }

  .inform-box-xiahuaxian {
    /deep/ .el-input__inner {
      border: none !important;
      border-radius: 0 !important;
      border-bottom: 1px solid #dcdfe6 !important;
      padding: 0 !important;
      color: #606266;
      font-size: 13px !important;
      height: 20px !important;
      line-height: 20px;
    }

    /deep/ .el-input {
      height: 20px !important;
      line-height: 20px !important;
    }
  }

  .fujian {
    width: 345px;

    img {
      width: 100%;
    }
  }

  .form-xing {
    color: #606266;

    &::after {
      content: "*";
      position: absolute;
      top: 32px;
      left: 7px;
      color: #f56c6c;
    }
  }

  .form-xing-xin {
    color: #606266;

    &::after {
      content: "*";
      position: absolute;
      top: 18px;
      left: 0px;
      color: #f56c6c;
    }
  }

  //悬浮
  .xuan {
    position: fixed;
    bottom: 30%;
    right: 2%;
    z-index: 9999999;

    .xuan-ru {
      position: relative;
      z-index: 9999999;

      .xuan-ru-img {
        width: 45px;
        height: 45px;
        transition: all 0.2s linear;

        &.anim {
          transform: rotate(180deg);
        }
      }
    }

    .xuan-ul {
      box-sizing: border-box;
      position: relative;
      top: -6px;
      right: -1px;
      width: 44px;
      // height: 325px;
      // height: 362px;
      // height: 365px;
      // height: 415px; 
      // height: 309px;
      height: 315px;
      background: url(../../assets/image/questionnaire/xuan-bgc.png) no-repeat center 0px;
      background-position: center 0px;
      background-size: cover;
      // background-color: #fff;
      padding: 20px 4px;

      .xuan-li {
        font-size: 12px;
        // padding: 6px;
        padding: 6px 5px 6px 0;
        padding-left: 6px;
        line-height: 1.1;
        margin-top: 10px;
        color: #409eff;

        &:nth-child(1) {
          margin-top: 2px;
        }
      }

      .xuan-li-active {
        font-size: 12px;
        background-image: linear-gradient(#96fcf6, #346ad8);
        color: #fff;
        border-radius: 11px;
      }
    }
  }

  .zhongyi-head {
    color: #000000;
    font-size: 14px;
    font-weight: 600;
    margin-bottom: 20px;
  }

  .zhongyi-text {
    border-radius: 8px;
    background-color: #e6f4ff;
    font-size: 13px;
    color: #111111;
    display: flex;
    padding: 16px 8px;
    margin-bottom: 25px;
    padding-bottom: 6px;

    .zhongyi-text-zhu {
      color: #ff2d2d;
    }

    .zhongyi-text-name {
      display: flex;
      flex-wrap: wrap;

      & div {
        margin-bottom: 9px;
        margin-right: 40px;

      }

      & div:nth-child(3) {
        margin-bottom: 0;
      }

      & div:nth-child(4) {
        margin-right: 54px;
        margin-bottom: 0;
      }
    }
  }

  .zhongyi-box {
    display: flex;
    align-items: center;
    width: 100%;
    margin-bottom: 30px;

    &:last-child {
      margin-bottom: 20px;
    }

    .zhongyi-box-head {
      margin-right: 15px;
      // width: 65px;
      width: 70px;
    }

    .zhongyi-box-slider {
      position: relative;

      .zhongyi-box-slider-item {
        width: 225px;
      }

      .form-zhongyi-box-slider-cen-name {
        position: absolute;
        // top: 10px;
        // left: -3.5px;
        top: 11px;
        left: -4px;
        z-index: 9999;
        color: #000;
        font-size: 13px;

        width: 0px;
        height: 0px;
      }

      .form-zhongyi-box-slider-cen-name1 {
        position: absolute;
        top: 11px;
        left: -3px;
        z-index: 9999;
        color: #000;
        font-size: 13px;
        width: 0px;
        height: 0px;
      }

      .form-zhongyi-box-slider-cen-zheng {
        position: absolute;
        top: 35px;
        left: -12px;
        z-index: 9999;
        font-size: 12px;
        color: #656f95;

        .zhong-zheng1 {
          position: absolute;
          top: 0px;
          left: -5px;
          width: 40px;
        }

        .zhong-zheng5 {
          position: absolute;
          top: 0px;
          right: -35px;
          width: 40px;
        }
      }

      // 后
      .form-zhongyi-box-slider-cen-zheng-hou {
        position: absolute;
        top: 35px;
        left: -12px;
        z-index: 9999;
        font-size: 12px;
        color: #656f95;

        .zhong-zheng1-hou {
          position: absolute;
          top: 0px;
          left: 5px;
          width: 40px;
        }

        .zhong-zheng2-hou {
          position: absolute;
          top: 0px;
          left: 53px;
          width: 40px;
        }

        .zhong-zheng3-hou {
          position: absolute;
          top: 0px;
          left: 100px;
          width: 60px;
        }

        .zhong-zheng4-hou {
          position: absolute;
          top: 0px;
          right: -50px;
          width: 60px;
        }

        .zhong-zheng5-hou {
          position: absolute;
          top: 0px;
          right: -40px;
          width: 40px;
        }
      }
    }
  }

  /deep/ .el-slider__bar {
    height: 8px;
    background: linear-gradient(90deg, #ffe6e6 0%, #db1f1f 100%);
  }

  /deep/ .el-slider__runway {
    height: 8px;
  }

  /deep/.el-slider__stop {
    width: 8px;
    height: 8px;
  }

  /deep/.el-slider__button {
    position: relative;
    /* border-color: #db1f1f; */
    border: 4px solid #cdcccf !important;
    width: 23px;
    height: 23px;
  }

  /deep/ .el-slider__marks-text {
    color: #000;
  }
}

.index .biaodan .huise /deep/ .el-input__inner {
  background-color: #f5f7f9 !important;
}

.ques /deep/ .el-input.is-disabled .el-input__inner {
  color: #606266 !important;
}

.ques /deep/ .el-textarea.is-disabled .el-textarea__inner {
  color: #606266 !important;
}

.ques /deep/ .el-radio__input.is-disabled.is-checked .el-radio__inner::after {
  background-color: #606266 !important;
}

.ques /deep/ .el-checkbox__input.is-disabled.is-checked .el-checkbox__inner::after {
  border-color: #606266 !important;
}

// .upload-demo {
//   background-color: #fff;
// }
// .upload-demo /deep/.el-upload-list {
//   background-color: #fff;
// }
//----------------------------------- -------
.xinde-radio {
  /deep/ .el-radio {
    margin-right: 38px !important;
  }
}

.xinde-radio-qita {
  /deep/ .el-radio {
    margin-right: 15px !important;
  }

  /deep/ .el-radio:last-child {
    margin-right: 0px !important;
  }
}

.inform-box-input {
  position: relative;

  .form-input-xia {
    position: absolute;
    top: -20px;
    left: 0;
    width: 182px;
  }

  /deep/ .el-input__inner {
    border: none !important;
    border-radius: 0 !important;
    border-bottom: 1px solid #dcdfe6 !important;
    // padding: 0 !important;
    color: #606266;
    font-size: 13px !important;
    height: 20px !important;
    line-height: 20px;
    // background-color: pink;
    padding-left: 15px;
  }

  /deep/ .el-input {
    height: 20px !important;
    line-height: 20px !important;
  }
}

.form-fenshu {
  display: flex;
  align-items: center;
  color: #111111;

  .form-fenshu-left {
    width: 70px;
  }

  .form-fenshu-right {
    background: linear-gradient(143deg, #C7CDFF 0%, #6777F2 100%);
    border-radius: 13px;
    color: #fff;
    font-weight: 600;
    width: 34px;
    height: 34px;
    font-size: 16px;
    text-align: center;
    line-height: 34px;
  }
}

.qita-tishi {
  position: relative;
  background-color: #FFFAF3;
  padding: 14px 8px 1px 8px;
  border-radius: 9px;
  font-size: 14px;
  margin-top: 20px;

  .qita-tishi-title {
    color: #FF2D2D;
    font-size: 14px;
    margin-bottom: 20px;
    font-weight: 600;
  }

  .qita-tishi-con {
    position: relative;
    display: flex;
    margin-bottom: 20px;

    .qita-tishi-con-h {
      color: #242424;
      font-weight: 600;
      margin-left: 12px;
      width: 70px;

      &::before {
        content: '';
        width: 6px;
        height: 6px;
        background-color: #FFB175;
        border-radius: 50%;
        position: absolute;
        top: 6px;
        left: 0;
      }
    }

    .qita-tishi-con-t {
      color: #242424;
      flex: 1;
    }
  }
}

.zhiliaofangan-group {
  display: flex;
  flex-direction: column;

  .zhiliaofangan-group-item {
    background-color: #F5F7F9;
    border-radius: 8px;
    padding: 18px 12px;
    margin-bottom: 10px;
  }

  .form-item-slider-left .el-form-item {
    margin-bottom: 0 !important;
  }
}
</style> 
 