<template>
  <div class="topic-details">
    <van-nav-bar title="病例集" fixed @click-left="$router.back()" />
    <div class="banner">
      <img src="@/assets/image/home/topicDetailsBanner-text.png" />
      <!-- <div class="banner-name" v-if="divisionCases">
        {{ divisionCases.activity_name }}
      </div> -->
      <div class="banner-name">
        <!-- {{ divisionInfo.activity_name }} -->
        <div>言 ' 荜 ' 有据，未来无 ' 幽 ' </div>
        <div>中西医结合根除幽门螺杆菌临床观察</div>
      </div>
    </div>
    <div class="tabs">
      <ul class="tabs-ul">
        <li :class="tabsIndex == index ? 'tabs-active' : ''" class="tabs-li" v-for="(item, index) in tabsList"
          :key="index" @click="tabsClick(index)">
          <div>{{ item }}</div>
          <transition name="tabs">
            <div v-if="tabsIndex == index" class="tabs-bottom"></div>
          </transition>
        </li>
      </ul>

      <ul class="tabs-content">
        <li class="tabs-content-x" v-if="tabsIndex == 0">
          <div class="tabs-content-x-box">
            <div class="x-box-nav">
              <div class="x-box-nav-img">
                <!-- <img > -->
                <div v-if="divisionInfo.video">
                  <video :poster="divisionInfo.cover" ref="video" :src="divisionInfo.video" :autoplay="videolist.autoplay"
                    :controls="videolist.controls" :controlslist="videolist.controlslist"
                    :webkit-playsinline="true"></video>
                </div>
                <div v-else style="width: 100%; height: 100%">
                  <img :src="divisionInfo.cover" />
                </div>
              </div>
              <div class="x-box-nav-btn">
                <div class="x-box-nav-btn-x" @click="publish">
                  <van-icon name="guide-o" />
                  <div class="x-box-nav-btn-x-text">发布病例</div>
                </div>

                <!-- <div
                  class="x-box-nav-btn-x"
                  @click="downloadTemplate"
                >
                
                  <div class="x-box-nav-btn-x-text">登录</div>
                </div> -->
              </div>
            </div>
            <div class="theme-box">
              <div class="theme-name">
                <img src="@/assets/image/home/theme-h.png" />
              </div>
              <div class="theme-bgc">
                <img src="@/assets/image/home/theme-bgc.png" />
              </div>
              <p class="theme-text" v-if="!divisionInfo.activity_theme">
                暂无数据
              </p>
              <p v-else class="theme-text" v-html="divisionInfo.activity_theme"></p>
            </div>
          </div>

          <div class="split"></div>

          <div class="Process" v-if="divisionInfo.entry_process_mobile">
            <img :src="divisionInfo.entry_process_mobile" />
          </div>

          <div class="participate-box">
            <div class="participate-img">
              <img src="@/assets/image/home/participate.png" />
            </div>
            <div class="participate-con">
              <div v-if="!divisionInfo.entry_information">暂无数据</div>
              <div class="participate-con-html" v-else v-html="divisionInfo.entry_information"></div>
            </div>
          </div>

          <!-- <div class="judges">
            <div class="judges-img">
              <img src="@/assets/image/home/judges.png">
            </div>
            <div>
              <van-empty
                v-if="!divisionInfo.expert_judge"
                description="暂无数据"
              />
              <ul
                v-else
                class="judges-ul"
              >
                <li
                  class="judges-li"
                  v-for="(item,index) in divisionInfo.expert_judge"
                  :key="index"
                >
                  <div class="judges-li-img">
                    <img :src="item.avatar">
                  </div>
                  <div class="judges-li-name"> {{item.name}}</div>
                </li>
              </ul>
            </div>
          </div> -->
        </li>

        <!-- 赛区 -->
        <!-- <li
          class="tabs-content-b"
          v-if="tabsIndex==1"
        >
          <div
            class="description"
            v-if="divisionCases"
          >
            <div class="description-img">
              <img :src="divisionCases.cover">
            </div>
            <div class="description-box">
              <div class="description-name">
                <img src="@/assets/image/home/description.png">
              </div>
              <div class="description-bgc">
                <img src="@/assets/image/home/theme-bgc.png">
              </div>
              <p class="description-text">
                {{divisionCases.desc}}
              </p>
            </div>
          </div>
          <div class="split"></div>
          <div class="competition">
            <div class="bing-xuan-diqu">
              <el-popover
                placement="bottom-start"
                title="选择比赛地区"
                width="200"
                trigger="click"
              >
                <el-tree
                  :data="data"
                  show-checkbox
                  node-key="id"
                  :props="defaultProps"
                  @check="checkBoxFn"
                  ref="tree"
                >
                </el-tree>
                <div
                  class="competition-btn"
                  slot="reference"
                >
                  <div
                    class="competition-btn-c"
                    v-if="checkBoxName.length==0"
                  > <i>选择比赛地区</i>
                    <i class="el-icon-arrow-down"></i>
                  </div>
                  <div
                    class="competition-btn-c-a"
                    v-else
                  >{{checkBoxName.toString()}}</div>
                </div>
              </el-popover>
            </div>

            <div class="competition-flex">
              <div class="bing-xuan-fenlei">
                <el-select
                  v-model="cateValue"
                  placeholder="选择分类"
                  filterable
                  clearable
                >
                  <el-option
                    v-for="item in cateLsit"
                    :key="item.id"
                    :label="item.name"
                    :value="item.id"
                  >
                  </el-option>
                </el-select>
              </div>
              <div class="competition-flex-btn">
                <el-button
                  type="primary"
                  style="background: #EF5840;border-color: #EF5840"
                  @click="queryClick"
                >查询</el-button>
              </div>
            </div>

          </div>
          <div>
            <van-list
              v-model="loading"
              :finished="finished"
              finished-text="没有更多了"
              @load="onLoad"
              class="recommended-ul"
            >
              <van-cell>
                <div
                  class="recommended-li"
                  v-for="(item,index) in dataList"
                  :key="index"
                  @click="$router.push({path: '/homeDetails',query: {id:item.id} })"
                >
                  <div class="recommended-li-l"></div>
                  <div class="recommended-li-h"></div>
                  <div class="recommended-li-text">
                    {{item.title}}
                  </div>
                  <div class="recommended-li-name"> {{item.author}}</div>
                </div>
              </van-cell>
            </van-list>
          </div>
        </li> -->
        <!-- 赛区 -->
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  name: "homeTopic",
  data() {
    return {
      data: [],
      defaultProps: {
        children: "child",
        label: "title",
      },
      count: 1,
      checkBoxData: [],
      checkBoxName: [],
      cateLsit: [],
      cateValue: "",
      competitionList: {},
      information: null,
      token: null,
      videolist: {
        autoplay: false, // 自动播放
        controls: "controls", //操作
        controlslist: "nodownload   noplaybackrate",
      }, //video配置
      limit: 5,
      page: 1,
      // tabsList: ['赛区信息', '赛区病例'],
      tabsList: ["项目详情"],
      tabsIndex: 0,
      divisionInfo: {},
      divisionCases: {},
      loading: false,
      finished: false,
      dataList: [],
      id: "1",
    };
  },
  watch: {
    $route(to, from) {
      // this.getMeApa();
    },
    deep: true, // 深度观察监听 设置为 true
    immediate: true, // 第一次初始化渲染就可以监听到
  },
  created() {
    this.information = JSON.parse(localStorage.getItem("information"));

    // this.getMeApa();
    // this.getRejectApi();
  },
  mounted() {
    this.token = localStorage.getItem("token");
    // this.getMeApa(); //个人信息
    this.getTopicDivisionInfo(); //赛区信息
  },
  methods: {
    //认证驳回弹窗
    async getRejectApi() {
      const { data: data } = await this.$http.post("auth/me");
      if (data.data.doctor.status == 3) {
        this.$dialog
          .alert({
            message: data.data.doctor.reason,
          })
          .then(() => { });
      }
    },
    //赛区分类
    async getCateApi() {
      const { data: data } = await this.$http.get("system/activityCate", {
        params: {
          act_id: this.id,
        },
      });
      this.cateLsit = data.data;
    },
    //赛区 选中的状态
    checkBoxFn(val, num) {
      let checkdata = this.$refs.tree.getCheckedNodes().filter((item) => {
        return !item.childrens;
      });
      this.checkBoxData = checkdata.map((item) => {
        return item.id;
      });
      this.checkBoxName = checkdata.map((item) => {
        return item.title;
      });
    },
    //赛区查询
    async queryClick() {
      this.page = 1;
      this.dataList = [];
      this.getTopicMedicalNew();
    },
    //获取个人信息
    async getMeApa() {
      const { data: data } = await this.$http.post("auth/me");
      localStorage.setItem("information", JSON.stringify(data.data));
      this.information = data.data;
    },
    //切换
    tabsClick(index) {
      this.tabsIndex = index;
    },
    //发布病例
    async publish() {
      // if (this.information == null) {
      //   this.$toast("您还未登录，请先登录");
      //   // setTimeout(() => {
      //   //   this.$router.push("/login");
      //   // }, 1500);
      //   return;
      // }
      this.$router.push({
        path: "/questionnaire",
        query: {},
      });
    },
    //登录
    async downloadTemplate() {
      this.$router.push("/login");
      // const { data: data } = await this.$http.get('medical/download', { params: { act_id: this.id } })
      // window.open(data.data.template_url)
    },
    //赛区信息
    async getTopicDivisionInfo() {
      const { data: data } = await this.$http.get("topic/division/info", {
        params: {
          act_id: this.id,
        },
      });
      this.divisionInfo = data.data;
    },
    //赛区病例 循環列表
    async getTopicMedicalNew() {
      this.checkBoxData = this.checkBoxData.toString();
      const { data: data } = await this.$http.post("topic/medicalNew", {
        act_id: this.id,
        cate_id: this.cateValue,
        area_id: this.checkBoxData,
        limit: this.limit,
        page: this.page,
      });
      // console.log(data);
      if (this.page <= data.data.pages) {
        this.dataList.push(...data.data.list);
        this.page++;
      }
      if (this.dataList.length >= data.data.total) {
        this.finished = true;
      }
    },
    //加载
    async onLoad() {
      this.loading = true;
      this.getTopicMedicalNew();
      setTimeout(() => {
        this.loading = false;
      }, 1500);
    },
  },
};
</script>
<style lang="less" scoped>
.topic-details {
  position: relative;

  /deep/.van-cell::after {
    border: 0 !important;
  }

  /deep/ .van-cell {
    padding: 0 !important;
  }

  .banner {
    position: relative;
    width: 100%;
    height: 96px;
    margin-bottom: 18px;

    .banner-name {
      // width: 274px;
      width: 290px;
      text-align: center;

      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 2;
      overflow: hidden;

      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      color: #fff;
      font-size: 18px;
    }
  }

  .tabs {
    .tabs-ul {
      position: relative;
      top: 0;
      left: -4%;
      width: 108%;
      background-color: #eef8f9;
      display: flex;
      padding: 0 15px;
    }

    .tabs-li {
      position: relative;
      padding: 14px 0;
      transition: border-color 0.3s, background-color 0.3s, color 0.3s;
      overflow: hidden;
    }

    .tabs-li:nth-child(1) {
      margin-right: 44px;
    }

    .tabs-li:nth-child(1)::before {
      content: "";
      position: absolute;
      top: 14px;
      right: -23px;
      width: 2px;
      height: 21px;
      background-color: #98c4c8;
    }

    .tabs-active {
      color: #ed330d;
    }

    .tabs-bottom {
      position: relative;
      bottom: -14px;
      left: 0;
      width: 100%;
      height: 3px;
      background-color: #ed330d;
    }
  }

  //tabs 内容
  .tabs-content {
    width: 100%;
    margin-top: 18px;

    // 赛区信息
    .tabs-content-x {
      width: 100%;

      .tabs-content-x-box {
        padding: 12px;
        border-radius: 7px;
        background-color: #eef8f9;

        .x-box-nav {
          display: flex;
          margin-bottom: 32px;

          // align-items: center;
          .x-box-nav-img {
            width: 192px;
            height: 108px;
            border-radius: 8px;
            overflow: hidden;

            video {
              width: 100%;
              height: 100%;
            }
          }

          .x-box-nav-btn {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            margin-left: 17px;
            flex: 1;
            color: #ffffff;

            .x-box-nav-btn-x {
              display: flex;
              align-items: center;
              justify-content: center;
              padding: 15px 6px;
              background-color: #0436ab;
              border-radius: 4px;

              .x-box-nav-btn-x-text {
                font-size: 13px;
                color: #fff;
                margin-left: 6px;
              }
            }
          }
        }

        //主旨
        .theme-box {
          position: relative;

          .theme-name {
            width: 97px;
            height: 41px;
          }

          .theme-bgc {
            width: 186px;
            height: 176px;
            position: absolute;
            top: -45px;
            left: 70px;
          }

          .theme-text {
            padding-bottom: 14px;
            margin-top: 23px;
            line-height: 2;
            font-size: 15px;
            color: #000;
            line-height: 1.7;
          }
        }
      }

      // 参赛流程
      .Process {
        width: 100%;
        // height: 733px;
        margin-bottom: 25px;
      }

      // 参赛信息
      .participate-box {
        margin-bottom: 65px;
        width: 100%;
        padding: 15px 12px;
        background-color: #eef8f9;
        border-radius: 7px;
        color: #393939;
        font-size: 13px;

        /deep/ .participate-con-html {
          width: 100%;
          white-space: pre-wrap !important;
          word-break: break-all !important;
          word-wrap: break-word !important;

          span,
          p,
          div {
            white-space: pre-wrap !important;
            word-break: break-all !important;
            word-wrap: break-word !important;
            line-height: 2;
            // line-height: 2;
            // font-size: 13px;
            // color: #000;
          }
        }

        .participate-img {
          width: 97px;
          height: 41px;
        }

        .participate-con {
          margin-top: 26px;
          white-space: pre-wrap;

          .participate-con-name {
            font-weight: 600;
            margin-bottom: 21px;
          }

          .participate-con-text {
            line-height: 1.7;
          }
        }
      }

      //评委
      .judges {
        margin-top: 43px;

        .judges-img {
          width: 67px;
          height: 19px;
          margin-bottom: 37px;
        }

        .judges-ul {
          display: flex;
          flex-wrap: wrap;
          min-height: 120px;
          max-height: 200px;
          overflow-y: auto;

          .judges-li {
            margin-right: 39px;
            // margin-bottom: 15px;
            margin-top: 15px;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;

            &:nth-child(1) {
              margin-top: 0px;
            }

            &:nth-child(2) {
              margin-top: 0px;
            }

            &:nth-child(3) {
              margin-top: 0px;
            }

            &:nth-child(4) {
              margin-top: 0px;
            }

            &:nth-child(4n) {
              margin-right: 0;
            }

            .judges-li-img {
              position: relative;
              width: 57px;
              height: 57px;
              // background-color: #04b5c4;
              background-color: #fff;
              border-radius: 50%;
              overflow: hidden;
              // img {
              //   position: absolute;
              //   top: 0;
              //   left: 5px;
              // }
            }

            .judges-li-name {
              margin-top: 16px;
              font-size: 13px;
              text-align: center;
              color: #363636;
              // width: 62px;
              // width: 57px;
              width: 43px;
              height: 19px;
              overflow: hidden;
              word-spacing: normal;
              text-overflow: ellipsis;
            }
          }
        }
      }
    }

    // 赛区病例
    .tabs-content-b {

      //专题
      .description {
        padding: 16px 12px;
        background-color: #eef8f9;
        border-radius: 7px;

        .description-img {
          width: 241px;
          height: 168px;
          // width: 298px;
          // height: 168px;
          margin: 0 auto;
        }

        .description-box {
          position: relative;
          margin-top: 30px;

          .description-name {
            width: 97px;
            height: 41px;
          }

          .description-bgc {
            width: 186px;
            height: 176px;
            position: absolute;
            top: -45px;
            left: 70px;
          }

          .description-text {
            margin-top: 23px;
            line-height: 2;
            font-size: 13px;
            color: #000;
            line-height: 1.7;
          }
        }
      }

      //分类
      .competition {
        background-color: #fff;

        .bing-xuan-fenlei {
          width: 100% !important;
        }

        .competition-btn {
          padding: 11px 13px;
          border: 1px solid #dcdfe6;
          font-size: 13px;
          border-radius: 5px;

          .competition-btn-c {
            display: flex;
            justify-content: space-between;
            align-items: center;
            color: #c0c4cc;
          }

          .competition-btn-c-a {
            color: #606266;
          }
        }

        .competition-flex {
          display: flex;
          align-items: center;
          margin-top: 17px;

          .bing-xuan-fenlei {
            width: 80%;

            /deep/ .el-select {
              width: 100%;
            }
          }

          .competition-flex-btn {
            flex: 1;
            margin-left: 8px;
          }
        }
      }

      // 列表
      .recommended-ul {
        position: relative;

        .recommended-li {
          position: relative;
          padding: 17px 13px 17px 26px;
          background-color: #fff;
          border-radius: 4px;
          box-shadow: 0px 3px 8px 1px #d7e9eb;
          margin-top: 14px;

          .recommended-li-l {
            position: absolute;
            top: 0;
            left: 0;
            width: 8px;
            height: 57px;
            background-color: #04b5c4;
          }

          .recommended-li-h {
            position: absolute;
            top: 7px;
            left: 6px;
            width: 8px;
            height: 30px;
            background-color: #ffead1;
          }

          .recommended-li-text {
            color: #393939;
            font-size: 13px;
            margin-bottom: 14px;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 2;
            overflow: hidden;
            line-height: 1.6;
            min-height: 41px;
          }

          .recommended-li-name {
            font-size: 13px;
            color: #fd4734;
          }
        }

        .recommended-li:last-child {
          margin-bottom: 18px;
        }
      }
    }

    // 分割
    .split {
      height: 1px;
      margin: 18px 0 23px;
      background-image: linear-gradient(to right,
          #cddadb 35%,
          rgba(255, 255, 255, 0) 0%);
      /* 35%设置虚线点x轴上的长度 */
      background-position: bottom;
      /* top配置上边框位置的虚线 */
      background-size: 10px 1px;
      /* 第一个参数设置虚线点的间距；第二个参数设置虚线点y轴上的长度 */
      background-repeat: repeat-x;
    }
  }

  //离开
  .tabs-leave-to {
    animation: puff-out-hor 0.7s cubic-bezier(0.165, 0.84, 0.44, 1) both;
  }

  @keyframes puff-out-hor {
    0% {
      transform: scaleX(1);
      filter: blur(0px);
      opacity: 1;
    }

    100% {
      transform: scaleX(2);
      filter: blur(4px);
      opacity: 0;
    }
  }
}
</style>
